import colors, { randomColor } from '../colors.js';
import { randomInt, alias } from '../util/index.js';
import withBase from './withBase.js';

const showGuides = false;

const typeface = {
  yUnits: 24,
  kerningUnit: 2,
  markFraction: 0.9,
  glyphData(glyph) {
    const pattern = [];

    const gap = randomInt(3, 8);
    const direction = Math.random() < 0.5;

    for (let i = 0; i < this.yUnits; i++) {
      for (let j = 0; j < this.yUnits; j++) {
        const index = i * this.yUnits + j;
        pattern[index] =
          (j + (direction ? -(i % gap) : i % gap)) % gap == 0 ? 1 : 0;
      }
    }

    const { yUnits } = this;
    const xUnits = yUnits;

    return {
      glyph,
      height: this.height,
      width: xUnits * this.unitSize,
      xUnits,
      yUnits,
      pattern: alias(xUnits, pattern),
      unitSize: this.unitSize,
    };
  },
  render({ xUnits, yUnits, width, unitSize, pattern, preDraw }) {
    const segments = this.subdivide * this.subdivide;

    for (let i = 0; i < yUnits; i++) {
      const y = i * unitSize;
      for (let j = 0; j < xUnits; j++) {
        const x = j * unitSize;

        for (let k = 0; k < segments; k++) {
          // const segmentSize = (unitSize * this.markFraction) / 2;
          const segmentSize = unitSize / this.subdivide;

          const innerSize = segmentSize * this.markFraction;
          const dif = (segmentSize - innerSize) / 2;
          preDraw.rectangle({
            x: x + (k % this.subdivide) * segmentSize + dif,
            y: y + Math.floor(k / this.subdivide) * segmentSize + dif,
            width: innerSize,
            height: innerSize,
            cornerRadius: segmentSize / 4,
            fill: this.colors.primary,
            opacity: pattern[i * xUnits + j] ? 1 : 0.3,
          });
        }
      }
    }
  },
};

export default withBase(typeface);
