export default `
precision highp float;

// Attributes
attribute vec3 position;
attribute vec3 normal;
attribute vec2 uv;
attribute vec2 instanceOffset;

// Uniforms
/* uniform mat4 view; */
// uniform mat4 world;
uniform mat4 worldViewProjection;
uniform mat4 viewProjection; 

#include<instancesDeclaration>; 

// Varying
varying vec3 vPositionW;
varying vec3 vNormalW;
varying vec3 vNormal;
varying vec2 vUV;
varying vec2 vInstanceOffset;

void main(void) {

     #include<instancesVertex>; 

    vec4 p = vec4( position, 1.0 );
    gl_Position = viewProjection * finalWorld * p;

    vPositionW = vec3(finalWorld * p);

    vNormalW =  normalize(vec3(finalWorld * vec4(normal, 0.0)));
    vNormal = normal;

    vUV = uv;

    vInstanceOffset= instanceOffset;
}
`;
