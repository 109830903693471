import { createShape } from "../util";
import { getShaderMaterial } from "../materials";

export default (
  {
    vertices,
    indices,
    name = "unnamed",
    shaderName,
    colors,
    shaderOptions = {},
  },
  scene
) => {
  if (indices && indices.length) {
    const lightIndiceRange = {
      min: indices.reduce((a, b) => Math.min(a, b)),
      max: indices.reduce((a, b) => Math.max(a, b)),
    };

    const lightVertices = vertices.slice(
      lightIndiceRange.min * 3,
      (lightIndiceRange.max + 1) * 3
    );

    indices = indices.map(indice => indice - lightIndiceRange.min);

    const lightMesh = createShape(
      `${name}LightMesh`,
      { vertices: lightVertices, indices: indices },
      scene
    );

    const lightMaterial = getShaderMaterial(scene, shaderName, {
      color: colors[2],
      ...shaderOptions,
    });

    lightMesh.material = lightMaterial;

    return lightMesh;
  } else {
    return null;
  }
};
