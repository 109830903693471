import defaultColors from "../colors.js";
import Draw from "../draw.js";
import withBase from "./withBase.js";

const cache = {};

const typeface = {
  yUnits: 5,
  markFraction: 0.9,
  subdivide: 1,
  render({ xUnits, yUnits, unitSize, pattern, glyph, preDraw }) {
    const { subdivide, colors, markFraction } = this;
    const segmentSize = unitSize / subdivide;
    for (let i = 0; i < yUnits * subdivide; i++) {
      const y = i * segmentSize;
      for (let j = 0; j < xUnits * subdivide; j++) {
        const x = j * segmentSize;
        const innerSize = segmentSize * markFraction;
        const isSolid = pattern[i * (xUnits * subdivide) + j];

        const shape = preDraw.circle({
          x: x + innerSize / 2,
          y: y + innerSize / 2,
          radius: innerSize / 2, //unitSize * (this.markFraction / 2),
          fill: isSolid ? colors.primary : colors.secondary,
          opacity: isSolid ? 1 : this.bgOpacity,
        });
      }
    }
  },
};

/* prettier-ignore */
typeface.glyphs =  {
  " ": [
    3,
  ],
  "A": [
    4,
    0,1,1,0,
    1,0,0,1,
    1,1,1,1,
    1,0,0,1,
    1,0,0,1,
  ],
  "B": [
    4,
    1,1,1,0,
    1,0,0,1,
    1,1,1,0,
    1,0,0,1,
    1,1,1,0,
  ],
  "C": [
    4,
    0,1,1,1,
    1,0,0,0,
    1,0,0,0,
    1,0,0,0,
    0,1,1,1,
  ],
  "D": [
    4,
    1,1,1,0,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
    1,1,1,0,
  ],
  "E": [
    4,
    1,1,1,1,
    1,0,0,0,
    1,1,1,0,
    1,0,0,0,
    1,1,1,1,
  ],
  "F": [
    4,
    1,1,1,1,
    1,0,0,0,
    1,1,1,0,
    1,0,0,0,
    1,0,0,0,
  ],
  "G": [
    4,
    0,1,1,1,
    1,0,0,0,
    1,0,0,1,
    1,0,0,1,
    0,1,1,1,
  ],
  "H": [
    4,
    1,0,0,1,
    1,0,0,1,
    1,1,1,1,
    1,0,0,1,
    1,0,0,1,
  ],
  "I": [
    3,
    1,1,1,
    0,1,0,
    0,1,0,
    0,1,0,
    1,1,1,
  ],
  "J": [
    4,
    1,1,1,1,
    0,0,1,0,
    0,0,1,0,
    0,0,1,0,
    1,1,0,0,
  ],
  "K": [
    4,
    1,0,0,1,
    1,0,1,0,
    1,1,0,0,
    1,0,1,0,
    1,0,0,1,
  ],
  "L": [
    3,
    1,0,0,
    1,0,0,
    1,0,0,
    1,0,0,
    1,1,1,
  ],
  "M": [
    5,
    1,1,0,1,1,
    1,0,1,0,1,
    1,0,1,0,1,
    1,0,0,0,1,
    1,0,0,0,1,
  ],
  "N": [
    4,
    1,0,0,1,
    1,1,0,1,
    1,0,1,1,
    1,0,0,1,
    1,0,0,1,
  ],
  "O": [
    4,
    0,1,1,0,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
    0,1,1,0,
  ],
  "P": [
    4,
    1,1,1,0,
    1,0,0,1,
    1,1,1,0,
    1,0,0,0,
    1,0,0,0,
  ],
  "Q": [
    4,
    0,1,1,0,
    1,0,0,1,
    1,0,0,1,
    1,0,1,0,
    0,1,0,1,
  ],
  "R": [
    4,
    1,1,1,0,
    1,0,0,1,
    1,1,1,0,
    1,0,0,1,
    1,0,0,1,
  ],
  "S": [
    4,
    1,1,1,1,
    1,0,0,0,
    0,1,1,0,
    0,0,0,1,
    1,1,1,1,
  ],
  "T": [
    3,
    1,1,1,
    0,1,0,
    0,1,0,
    0,1,0,
    0,1,0,
  ],
  "U": [
    4,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
    0,1,1,0,
  ],
  "V": [
    4,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
    0,1,1,1,
  ],
  "W": [
    5,
    1,0,0,0,1,
    1,0,0,0,1,
    1,0,1,0,1,
    1,0,1,0,1,
    1,1,0,1,1,
  ],
  "X": [
    4,
    1,0,0,1,
    1,0,0,1,
    0,1,1,0,
    1,0,0,1,
    1,0,0,1,
  ],
  "Y": [
    3,
    1,0,1,
    1,0,1,
    0,1,0,
    0,1,0,
    0,1,0,
  ],
  "Z": [
    4,
    1,1,1,1,
    0,0,0,1,
    0,1,1,0,
    1,0,0,0,
    1,1,1,1,
  ],
  "0": [
    4,
    0,1,1,0,
    1,0,0,1,
    1,0,1,1,
    1,1,0,1,
    0,1,1,0,
  ],
  "1": [
    3,
    1,1,0,
    0,1,0,
    0,1,0,
    0,1,0,
    1,1,1,
  ],
  "2": [
    4,
    0,1,1,0,
    1,0,0,1,
    0,1,1,1,
    1,0,0,0,
    1,1,1,1,
  ],
  "3": [
    4,
    1,1,1,1,
    0,0,0,1,
    0,1,1,0,
    0,0,0,1,
    1,1,1,0,
  ],
  "4": [
    4,
    0,0,1,0,
    0,1,1,0,
    1,0,1,0,
    1,1,1,1,
    0,0,1,0,
  ],
  "5": [
    4,
    1,1,1,1,
    1,0,0,0,
    0,1,1,0,
    0,0,0,1,
    1,1,1,0,
  ],
  "6": [
    4,
    0,1,1,1,
    1,0,0,0,
    1,1,1,0,
    1,0,0,1,
    0,1,1,0,
  ],
  "7": [
    4,
    1,1,1,1,
    0,0,0,1,
    0,0,1,0,
    0,1,0,0,
    0,1,0,0,
  ],
  "8": [
    4,
    0,1,1,0,
    1,0,0,1,
    0,1,1,0,
    1,0,0,1,
    0,1,1,0,
  ],
  "9": [
    4,
    1,1,1,1,
    1,0,0,1,
    1,1,1,1,
    0,0,1,0,
    0,1,0,0,
  ],
  "#": [
    5,
    0,0,1,0,1,
    1,1,1,1,1,
    0,1,0,1,0,
    1,1,1,1,1,
    1,0,1,0,0,
  ],
  "," : [
    2,
    0,0,
    0,0,
    0,0,
    1,1,
    0,1,
  ],
  "." : [
    2,
    0,0,
    0,0,
    0,0,
    0,0,
    1,0,
  ],
  "-": [
    4,
    0,0,0,0,
    0,0,0,0,
    1,1,1,1,
    0,0,0,0,
    0,0,0,0,
  ],
  "_": [
    4,
    0,0,0,0,
    0,0,0,0,
    0,0,0,0,
    0,0,0,0,
    1,1,1,1,
  ],
  "%": [
    5,
    1,1,0,0,1,
    1,1,0,1,0,
    0,0,1,0,0,
    0,1,0,1,1,
    1,0,0,1,1,
  ],
  "+": [
    5,
    0,0,1,0,0,
    0,0,1,0,0,
    1,1,1,1,1,
    0,0,1,0,0,
    0,0,1,0,0,
  ],
  "&": [
    5,
    0,1,1,0,0,
    1,0,0,1,0,
    0,1,1,0,0,
    1,0,0,1,0,
    0,1,1,0,1,
  ],
  "!" : [
    2,
    1,1,
    1,1,
    1,1,
    0,0,
    1,1
  ],
  ":" : [
    2,
    1,1,
    1,1,
    0,0,
    1,1,
    1,1,
  ],
  ">" : [
    8,
    0,0,0,1,1,0,0,0,
    0,0,0,1,1,1,0,0,
    1,1,1,1,1,1,1,0,
    0,0,0,1,1,1,0,0,
    0,0,0,1,1,0,0,0,
  ]

};

export default withBase(typeface);
