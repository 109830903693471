import * as typefaces from "./typefaces/index.js";

export const calculateWidth = (typefaceName, height, content) => {
  const width = 0;
  const t = typefaces[typefaceName]().init({ height });
  return t.getWidth(content);
};

export const calculateHeight = (typefaceName, height, content) => {
  const t = typefaces[typefaceName]().init({ height });
  return t.getHeight(content);
};

export default (draw) => ({
  typeface: typefaceName,
  height,
  colors,
  x = 0,
  y = 0,
  invert,
  padding,
  content,
  noise,
}) => {
  if (!typefaces[typefaceName]) {
    throw `Typeface does not exist: '${typefaceName}'`;
  }

  const typeface = typefaces[typefaceName]();

  typeface.init({ height, colors, draw, invert, padding, noise });

  let lines;
  const charCount = {};
  if (Array.isArray(content)) {
    lines = content;
  } else {
    lines = content.split("\n");
  }

  const currentOffset = { x, y };

  lines.forEach((line, index) => {
    const chars = line.split("");
    const lastCharIndex = chars.length - 1;

    const letterSpacing = typeface.letterSpacing;

    if (typeface.padding) {
      typeface.renderSpace(typeface.padding, currentOffset);
      currentOffset.x += typeface.space(typeface.padding);
    }

    chars.forEach((char, i) => {
      charCount[char] = charCount[char] ? charCount[char] + 1 : 1;
      const glyphData = typeface.glyphData(char);
      if (!glyphData) return;
      typeface.renderGlyph(glyphData, currentOffset, charCount[char]);
      currentOffset.x += glyphData.width; //+ typeface.space(1);
      if (!(lastCharIndex === i)) {
        const letterSpacing = typeface.letterSpacing;
        typeface.renderSpace(letterSpacing, currentOffset);
        currentOffset.x += typeface.space(letterSpacing);
      }
    });
    if (typeface.padding) {
      typeface.renderSpace(typeface.padding, currentOffset);
      currentOffset.x += typeface.space(typeface.padding);
    }
    currentOffset.x = x;
    currentOffset.y += typeface.height;
    if (index < lines.length - 1) {
      currentOffset.y += typeface.space(typeface.lineSpacing);
    }
  });
  return {
    y: currentOffset.y,
    height: currentOffset.y - y,
  };
};
