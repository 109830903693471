import { ShaderMaterial } from "@babylonjs/core";

export default scene => {
  const material = new ShaderMaterial(
    "transparentShader",
    scene,
    {
      vertex: "transparent",
      fragment: "transparent",
    },
    {
      needAlphaBlending: true,
      uniforms: [
        "world",
        "worldView",
        "worldViewProjection",
        "view",
        "projection",
      ],
    }
  );

  material.freeze();

  return material;
};
