export default `
precision highp float;

// Attributes
attribute vec3 position;

// Uniforms
uniform mat4 viewProjection;

#include<instancesDeclaration>;

void main(void) {

    #include<instancesVertex>;
    vec4 p = vec4( position, 1.0 );
    gl_Position = viewProjection * finalWorld * p;
}
`;
