export default `precision highp float;


// Refs
uniform mat4 world;
uniform sampler2D t1;
uniform sampler2D bump;
uniform vec2 textureOffset;
uniform vec2 textureScale;
uniform vec3 lightPositions[8];
uniform vec3 lightColors[8];
uniform float ambientLightStrength;
uniform float minimalAmbientLight;
uniform vec3 colors[3];

// Lights
varying vec3 vPositionW;
varying vec3 vNormalW;
varying vec2 vUV;
varying vec3 vNormal;

#define FOGMODE_NONE 0.
#define FOGMODE_EXP 1.
#define FOGMODE_EXP2 2.
#define FOGMODE_LINEAR 3.
#define E 2.71828

uniform vec4 vFogInfos;
uniform vec3 vFogColor;
varying float fFogDistance;

float CalcFogFactor()
{
 float fogCoeff = 1.0;
 float fogStart = vFogInfos.y;
 float fogEnd = vFogInfos.z;
 float fogDensity = vFogInfos.w;

 if (FOGMODE_LINEAR == vFogInfos.x)
 {
  fogCoeff = (fogEnd - fFogDistance) / (fogEnd - fogStart);
 }
 else if (FOGMODE_EXP == vFogInfos.x)
 {
  fogCoeff = 1.0 / pow(E, fFogDistance * fogDensity);
 }
 else if (FOGMODE_EXP2 == vFogInfos.x)
 {
  fogCoeff = 1.0 / pow(E, fFogDistance * fFogDistance * fogDensity * fogDensity);
 }

 return clamp(fogCoeff, 0.0, 1.0);
}


mat3 makeScale(vec2 s) {
    mat3 m = mat3( s.x, 0, 0, 0, s.y, 0, 0, 0, 1);
    return m;
}

void main(void) {

    // Light

    vec2 textureCoord = textureScale * vUV;
    textureCoord += textureOffset;

    vec4 rgbTex = texture2D(bump, textureCoord);

    vec3 textureNormal = normalize(rgbTex * 2. - 1.).rgb;

    textureNormal = mix(textureNormal, vec3(0,0,1.), 0.5);

    textureNormal.z = -textureNormal.z;

    vec3 newNormal  =  normalize(vec3(world * vec4(textureNormal, 0.0)));

    vec4 texel4 = texture2D(t1, textureCoord ).rgba;

    vec3 texel = texel4.rgb;

    // Lighting
    float diffuse = 0.;
    vec3 lightColor;

    for (int i = 0; i < 8; i++) {
        vec3 lightCol = lightColors[i];
      
        if ((lightCol.r + lightCol.g + lightCol.b) == 0.) {
          continue;
        }
        vec3 lightDir = normalize(lightPositions[i] - vPositionW);
        lightColor += max(dot(vNormalW, lightPositions[i]), 0.0) * lightColors[i];

        float ndl = max(dot(vNormalW, lightDir), 0.0);
        ndl *= ambientLightStrength;
        diffuse += ndl; 
    }

    vec3 colorWithLighting = max(minimalAmbientLight, diffuse) * colors[0];

    float alpha = texel4.a;

    vec3 color = colors[1];

    color = mix(colorWithLighting, color, alpha);

    float fog = CalcFogFactor();

    color.rgb = mix(colors[0], color, vPositionW.y / 100.);
    color.rgb = fog * color.rgb + (1.0 - fog) * vFogColor;

    gl_FragColor = vec4(color, 1.); 
}`;
