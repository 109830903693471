import {
  minimalAmbientLight,
  ambientLightStrength,
  lightPositions,
} from './shaderVars';

import { Color3, ShaderMaterial } from '@babylonjs/core';

export default function instanceShader(scene, color) {
  const cellColor = Color3.FromHexString(color || '#ff0000');

  const material = new ShaderMaterial(
    'instanceShader',
    scene,
    {
      vertex: 'instance',
      fragment: 'instance',
    },
    {
      attributes: ['position', 'normal', 'uv'],
      uniforms: [
        'world',
        // "worldView",
        // "worldViewProjection",
        'viewProjection',
        // "view",
        // "projection",
      ],
      // defines: ["#define INSTANCES"],
    }
  );

  material.setColor3('color', cellColor);
  material.setVector3('lightPosition', lightPositions[0]);

  material.setFloat('minimalAmbientLight', minimalAmbientLight);
  material.setFloat('ambientLightStrength', ambientLightStrength);

  material.freeze();

  return material;
}
