export default (xUnits, arr, alias = 3) => {
  const clone = [...arr];
  const getNeighbors = (index) => {
    const neighbors = [
      index - 1 - xUnits,
      index - xUnits,
      index - xUnits + 1,
      index + 1,
      index + xUnits + 1,
      index + xUnits,
      index + xUnits - 1,
      index - 1,
    ];
    // top
    if (index < xUnits) {
      neighbors[0] = null;
      neighbors[1] = null;
      neighbors[2] = null;
    }
    // left
    if (index % xUnits === 0) {
      neighbors[0] = null;
      neighbors[6] = null;
      neighbors[7] = null;
    }
    // right
    if ((index + 1) % xUnits === 0) {
      neighbors[2] = null;
      neighbors[3] = null;
      neighbors[4] = null;
    }
    // bottom
    return neighbors;
  };
  for (let i = 0; i < arr.length; i++) {
    const neighbors = getNeighbors(i);
    let nCount = 0;
    neighbors.forEach((neighbor) => {
      if (!neighbor) return;
      if (arr[neighbor]) nCount++;
      // if (arr[neighbor] !== 'undefined') {
      // }
    });
    // console.log(nCount);
    if (nCount > alias) {
      // TODO: add most common neighbor type
      clone[i] = 1;
    }
  }
  return clone;
};
