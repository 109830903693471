import { transparentShader, getShaderMaterial, shaderVars } from "../materials";
import {
  BlockModel,
  getWalls,
  flattenVertices,
  getShaderName,
  createShape,
} from "../util";
import renderLightMesh from "./renderLightMesh";
import renderTextureMesh from "./renderTextureMesh";
import { Vector3, Mesh, Color4, MeshBuilder } from "@babylonjs/core";

function showMeshEdges(mesh) {
  mesh.enableEdgesRendering(EPSILON);
  mesh.edgesWidth = EDGE_WIDTH;
  mesh.edgesColor = EDGE_COLOR;
}

const showEdges = false;
const showPoints = false;
const showOutline = true;
const showThrust = false;

const EPSILON = 0.95;
const EDGE_WIDTH = 0.4;
const EDGE_COLOR = new Color4(0, 0, 0, 1);
const PERF = true;

export default (blockData, racerData, scene) => {
  const { meta } = racerData;
  const { colors, version } = racerData;
  const [bg, color, thrustColor] = colors;

  const materials = [];

  let { indices, vertices, scale, rotate } = blockData;

  const inverted = scale.x + scale.y + scale.z < 3;
  if (inverted) {
    indices.reverse();
  }

  const blockMesh = createShape("blockMesh", { vertices, indices }, scene);

  const blockMaterial = getShaderMaterial(
    scene,
    getShaderName(
      version === 1.1 ? racerData.shaders.block.shader : blockData.shader,
      meta.name
    ),
    {
      ...shaderVars,
      colors,
    }
  );
  const lightMaterial = getShaderMaterial(
    scene,
    // "light",
    getShaderName("light", meta.name),
    { color: colors[2] }
  );

  materials.push(blockMaterial);

  blockMesh.material = blockMaterial;

  if (showOutline) {
    // const blockMeshOutline = createShape(
    //   "outlineMesh",
    //   {
    //     vertices,
    //     indices,
    //     flatShading: false,
    //   },
    //   scene
    // );

    const blockMeshOutline = blockMesh.clone("blockMeshOutline");

    const transparentMaterial = transparentShader(scene);
    blockMeshOutline.material = transparentMaterial;

    // if (showEdges) {
    showMeshEdges(blockMeshOutline);
    // }

    blockMeshOutline.parent = blockMesh;
  }

  blockMesh.setPositionWithLocalVector(
    new Vector3(
      blockData.position.x,
      blockData.position.y,
      blockData.position.z
    )
  );
  blockMesh.receiveShadows = true;
  blockMesh.scaling = new Vector3(
    blockData.scale.x,
    blockData.scale.y,
    blockData.scale.z
  );

  let { lightIndices } = blockData;

  const lightMesh = renderLightMesh(
    {
      vertices,
      indices: lightIndices,
      name: racerData.meta.name,
      colors,
      shaderName: getShaderName("light", racerData.meta.name),
    },
    scene
  );

  if (lightMesh) {
    lightMesh.parent = blockMesh;
    lightMesh.name = "lightMesh";
  }

  const size = 0.2;

  let i = 0;

  const textureMesh = renderTextureMesh(
    {
      textureFaces: blockData.textureFaces,
      textures: blockData.textures,
      materials,
      colors,
      vertices,
      inverted,
      shaderName: getShaderName(
        version === 1.1
          ? racerData.shaders.texture.shader
          : blockData.textureShader,
        meta.name
      ),
    },
    scene
  );
  textureMesh.parent = blockMesh;

  if (rotate) {
    blockMesh.rotate(
      new Vector3(rotate.axis[0], rotate.axis[1], rotate.axis[2]),
      rotate.amount
    );
  }

  if (showThrust) {
    const thrustLength = 400;

    (blockData.thrusters || []).forEach(
      ({ normal: oldNormal, vertices: oldVertices }) => {
        console.log("Rendering thrust");
        const vertices = oldVertices.map(v => new Vector3(v._x, v._y, v._z));
        const normal = oldNormal.x
          ? new Vector3(oldNormal.x, oldNormal.y, oldNormal.z)
          : new Vector3(oldNormal._x, oldNormal._y, oldNormal._z);

        const newVertices = vertices.map(vertice =>
          vertice.add(normal.clone().scale(thrustLength))
        );

        const thrustIndices = getWalls(4, 2);

        const thrustMesh = createShape(
          "thrustMesh",
          {
            vertices: flattenVertices([...vertices, ...newVertices]),
            indices: thrustIndices,
          },
          scene
        );

        thrustMesh.castsShadow = false;
        thrustMesh.material = lightMaterial;
        thrustMesh.parent = blockMesh;
      }
    );
  }

  return {
    mesh: blockMesh,
    materials,
    data: blockData,
  };
};
