import { Mesh } from "@babylonjs/core";
import { getShaderMaterial, shaderVars } from "../materials";
import { createShape } from "../util";

export default (
  {
    textureFaces,
    materials,
    textures,
    shaderName,
    colors,
    vertices,
    inverted,
    shaderOptions,
  },
  scene
) => {
  const textureMesh = new Mesh();

  for (let textureFace of textureFaces) {
    let { textureIndices, textureId, textureUvs } = textureFace;

    const texture = textures[textureId];

    const textureMaterial = getShaderMaterial(scene, shaderName, {
      scale: { u: 1, v: 1 },
      position: { u: 0, v: 0 },
      texture,
      colors,
      ...shaderVars,
      ...shaderOptions,
    });

    if (materials) {
      materials.push(textureMaterial);
    }

    const indiceRange = {
      min: textureIndices.reduce((a, b) => Math.min(a, b)),
      max: textureIndices.reduce((a, b) => Math.max(a, b)),
    };

    const textureVertices = vertices.slice(
      indiceRange.min * 3,
      (indiceRange.max + 1) * 3
    );

    textureIndices = textureIndices.map(indice => indice - indiceRange.min);

    if (inverted) {
      textureIndices.reverse();
    }

    const textureFaceMesh = createShape(
      "textureFaceMesh",
      { vertices: textureVertices, indices: textureIndices, uvs: textureUvs },
      scene
    );

    textureFaceMesh.material = textureMaterial;
    textureFaceMesh.parent = textureMesh;
  }

  return textureMesh;
};
