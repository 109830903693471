import { Vector3 as V3, Axis } from "@babylonjs/core";

const TWO_PI = Math.PI * 2;

export function randomColor() {
  return "#000000".replace(/0/g, function() {
    return (~~(Math.random() * 16)).toString(16);
  });
}

export function randomFloat(min, max) {
  if (!max) {
    max = min;
    min = 0;
  }
  return Math.random() * (max - min) + min;
}

export const random = randomFloat;

export function randomInt(min, max) {
  if (!max) {
    max = min;
    min = 0;
  }
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function randomBool() {
  return Math.random() >= 0.5;
}

export function randomFromArray(arr) {
  return arr[randomInt(0, arr.length - 1)];
}

export function randomSign() {
  return Math.round(Math.random()) * 2 - 1;
}

export function randomPosition(mesh, xLimit, yLimit, zLimit) {
  const position = new V3(
    Array.isArray(xLimit)
      ? random(xLimit[0], xLimit[1])
      : random(-xLimit / 2, xLimit / 2),
    Array.isArray(yLimit)
      ? random(yLimit[0], yLimit[1])
      : random(-yLimit / 2, yLimit / 2),
    Array.isArray(zLimit)
      ? random(zLimit[0], zLimit[1])
      : random(-zLimit / 2, zLimit / 2)
  );
  mesh.position = position;
}

export function randomRotation(mesh) {
  mesh.rotate(Axis.X, random(TWO_PI));
  mesh.rotate(Axis.Y, random(TWO_PI));
  mesh.rotate(Axis.Z, random(TWO_PI));
}
