import withBase from "./withBase.js";
import alias from "../util/alias.js";
import subdivide from "../util/subdivide.js";

const showGuides = false;

const typeface = {
  yUnits: 8,
  markFraction: 0.8,
  subdivide: 2,
  alias: 6,
  noise: 12,
  letterSpacing: 0.5,
  lineSpacing: 0.5,
  // glyphdata(glyph) {
  //   const data = this.glyphs[glyph] || null;

  //   let [xUnits, ...pattern] = data || [4];
  //   const { yUnits } = this;

  //   pattern = subdivide(pattern, xUnits, this.subdivide);

  //   return {
  //     glyph,
  //     height: this.height,
  //     width: xUnits * this.unitSize,
  //     xUnits,
  //     yUnits,
  //     pattern: alias(xUnits * this.subdivide, pattern, 8),
  //     unitSize: this.unitSize,
  //   };
  // },
  render({ xUnits, yUnits, width, unitSize, pattern, preDraw, offset }) {
    const segmentSize = unitSize / this.subdivide;

    for (let i = 0; i < yUnits * this.subdivide; i++) {
      const y = i * segmentSize;
      for (let j = 0; j < xUnits * this.subdivide; j++) {
        const x = j * segmentSize;

        const innerSize = segmentSize * this.markFraction;
        const shouldDraw = pattern[i * (xUnits * this.subdivide) + j];
        if (shouldDraw) {
          if (Math.random() < 0.5) {
            // if (false) {
            preDraw.line({
              x1: x,
              y1: y,
              x2: x + innerSize,
              y2: y + innerSize,
              stroke: this.colors.primary,
              strokeWeight: 1, //window.devicePixelRatio, // 1
            });
            preDraw.line({
              x1: x + innerSize,
              y1: y,
              x2: x,
              y2: y + innerSize,
              stroke: this.colors.primary,
              strokeWeight: 1, //window.devicePixelRatio, // 1
            });
          } else {
            preDraw.rectangle({
              x,
              y,
              width: innerSize,
              height: innerSize,
              cornerRadius: segmentSize / 4,
              fill: this.colors.primary,
              opacity: shouldDraw ? 1 : 0,
            });
          }
        }
      }
    }
  },
};

/* prettier-ignore */
typeface.glyphs =  {
  " ": [4],
"/": [
      7,
      1,1,1,1,1,1,1,
      1,0,0,0,0,0,1,
      1,0,1,0,1,0,1,
      1,0,0,0,0,0,1,
      1,1,1,1,1,1,1,
      0,0,1,0,1,0,0,
      1,1,0,0,1,0,0,
      0,0,0,0,0,1,0,
  ],
  "A": [
      7,
      0,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
  ],
  "B": [
      7,
      1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,0,
      1,1,1,1,1,1,0,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,0,
  ],
  "C": [
      6,
      0,1,1,1,1,1,
      1,1,1,1,1,1,
      1,1,0,0,0,0,
      1,1,0,0,0,0,
      1,1,0,0,0,0,
      1,1,0,0,0,0,
      1,1,1,1,1,1,
      0,1,1,1,1,1,
  ],
  "D": [
      7,
      1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,0,
  ],
  "E": [
      6,
      1,1,1,1,1,1,
      1,1,1,1,1,1,
      1,1,0,0,0,0,
      1,1,1,1,1,0,
      1,1,1,1,1,0,
      1,1,0,0,0,0,
      1,1,1,1,1,1,
      1,1,1,1,1,1,
  ],
  "F": [
      7,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,
      1,1,1,1,1,1,0,
      1,1,1,1,1,1,0,
      1,1,0,0,0,0,0,
      1,1,0,0,0,0,0,
      1,1,0,0,0,0,0,
  ],
  "G": [
      7,
      0,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,
      1,1,0,1,1,1,1,
      1,1,0,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,
  ],
  "H": [
      7,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
  ],
  "I": [
      6,
      1,1,1,1,1,1,
      1,1,1,1,1,1,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      1,1,1,1,1,1,
      1,1,1,1,1,1,
  ],
  "J": [
      6,
      0,0,0,0,1,1,
      0,0,0,0,1,1,
      0,0,0,0,1,1,
      0,0,0,0,1,1,
      0,0,0,0,1,1,
      0,0,0,0,1,1,
      1,1,1,1,1,1,
      1,1,1,1,1,0,
  ],
  "K": [
      8,
      1,1,0,0,0,0,1,1,
      1,1,0,0,0,0,1,1,
      1,1,0,0,0,1,1,1,
      1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,0,
      1,1,0,0,0,1,1,1,
      1,1,0,0,0,0,1,1,
      1,1,0,0,0,0,1,1,
  ],
  "L": [
      6,
      1,1,0,0,0,0,
      1,1,0,0,0,0,
      1,1,0,0,0,0,
      1,1,0,0,0,0,
      1,1,0,0,0,0,
      1,1,0,0,0,0,
      1,1,1,1,1,1,
      1,1,1,1,1,1,
  ],
  "M": [
      8,
      1,1,0,0,0,0,1,1,
      1,1,1,0,0,1,1,1,
      1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,
      1,1,0,1,1,0,1,1,
      1,1,0,0,0,0,1,1,
      1,1,0,0,0,0,1,1,
      1,1,0,0,0,0,1,1,
  ],
  "N": [
      7,
      1,1,0,0,0,1,1,
      1,1,1,0,0,1,1,
      1,1,1,1,0,1,1,
      1,1,1,1,1,1,1,
      1,1,0,1,1,1,1,
      1,1,0,0,1,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
  ],
  "O": [
      7,
      0,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,1,
      0,1,1,1,1,1,0,
  ],
  "P": [
      7,
      1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,0,
      1,1,0,0,0,0,0,
      1,1,0,0,0,0,0,
      1,1,0,0,0,0,0,
  ],
  "Q": [
      7,
      0,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,1,1,0,
      1,1,1,1,1,1,1,
      0,1,1,1,0,1,1,
  ],
  "R": [
      7,
      1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,0,
      1,1,1,1,1,1,0,
      1,1,0,0,1,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
  ],
  "S": [
      7,
      0,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,
      1,1,1,1,1,1,0,
      0,1,1,1,1,1,1,
      0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,0,
  ],
  "T": [
      6,
      1,1,1,1,1,1,
      1,1,1,1,1,1,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
  ],
  "U": [
      7,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,1,
      0,1,1,1,1,1,0,
  ],
  "V": [
      7,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,1,0,1,1,1,
      0,1,1,1,1,1,0,
      0,0,1,1,1,0,0,
  ],
  "W": [
      8,
      1,1,0,0,0,0,1,1,
      1,1,0,0,0,0,1,1,
      1,1,0,0,0,0,1,1,
      1,1,0,1,1,0,1,1,
      1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,
      1,1,1,0,0,1,1,1,
      1,1,0,0,0,0,1,1,
  ],
  "X": [
      8,
      1,1,0,0,0,0,1,1,
      1,1,1,0,0,1,1,1,
      0,1,1,1,1,1,1,0,
      0,0,1,1,1,1,0,0,
      0,0,1,1,1,1,0,0,
      0,1,1,1,1,1,1,0,
      1,1,1,0,0,1,1,1,
      1,1,0,0,0,0,1,1,
  ],
  "Y": [
      6,
      1,1,0,0,1,1,
      1,1,0,0,1,1,
      1,1,0,0,1,1,
      1,1,1,1,1,1,
      0,1,1,1,1,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
  ],
  "Z": [
      7,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
      0,0,0,0,1,1,1,
      0,0,0,1,1,1,0,
      0,1,1,1,0,0,0,
      1,1,1,0,0,0,0,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
  ],
  "0": [
      7,
      0,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,1,0,0,1,1,
      1,1,0,1,0,1,1,
      1,1,0,0,1,1,1,
      1,1,1,1,1,1,1,
      0,1,1,1,1,1,0,
  ],
  "1": [
      6,
      0,1,1,1,0,0,
      1,1,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      1,1,1,1,1,1,
      1,1,1,1,1,1,
  ],
  "2": [
      7,
      0,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      0,0,0,0,1,1,1,
      0,0,1,1,1,1,1,
      0,1,1,1,1,0,0,
      1,1,1,0,0,0,0,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
  ],
  "3": [
      7,
      0,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      0,0,0,0,1,1,1,
      0,0,1,1,1,1,0,
      0,0,1,1,1,1,0,
      0,0,0,0,1,1,1,
      1,1,1,1,1,1,1,
      0,1,1,1,1,1,0,
  ],
  "4": [
      8,
      0,0,0,0,1,1,1,0,
      0,0,0,1,1,1,1,0,
      0,0,1,1,1,1,1,0,
      0,1,1,1,0,1,1,0,
      1,1,1,0,0,1,1,0,
      1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,
      0,0,0,0,1,1,1,0,
  ],
  "5": [
      7,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,
      1,1,1,1,1,1,0,
      0,1,1,1,1,1,1,
      0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,
      0,1,1,1,1,1,0,
  ],
  "6": [
      7,
      0,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,
      1,1,0,1,1,1,1,
      1,1,1,1,1,1,1,
      1,1,1,0,0,1,1,
      1,1,1,1,1,1,1,
      0,1,1,1,1,1,0,
  ],
  "7": [
      7,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
      0,0,0,0,1,1,1,
      0,0,0,1,1,1,0,
      0,0,1,1,1,0,0,
      0,1,1,1,0,0,0,
      0,1,1,1,0,0,0,
      0,1,1,1,0,0,0,
  ],
  "8": [
      8,
      0,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,
      1,1,1,0,0,1,1,1,
      0,1,1,1,1,1,1,0,
      0,1,1,1,1,1,1,0,
      1,1,1,0,0,1,1,1,
      1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,0,
  ],
  "9": [
      7,
      0,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      1,1,0,0,1,1,1,
      1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,
      0,0,0,0,0,1,1,
      0,0,0,0,1,1,1,
      0,0,0,1,1,1,0,
  ],
  "_": [
      4,
      1,1,1,1,
      1,0,0,1,
      1,0,0,1,
      1,0,0,1,
      1,0,0,1,
      1,0,0,1,
      1,0,0,1,
      1,0,0,1,
  ],
  "-": [
      4,
      0,0,0,0,
      0,0,0,0,
      0,0,0,0,
      1,1,1,1,
      1,1,1,1,
      0,0,0,0,
      0,0,0,0,
      0,0,0,0,
  ],
  "+": [
      3,
      1,0,1,
      1,0,1,
      1,0,1,
      1,1,1,
      0,1,0,
      0,1,0,
      0,1,0,
      0,1,0,
  ],
  "#": [
      7,
      0,0,0,0,0,0,0,
      0,1,1,0,1,1,0,
      1,1,1,1,1,1,1,
      0,1,1,0,1,1,0,
      0,1,1,0,1,1,0,
      1,1,1,1,1,1,1,
      0,1,1,0,1,1,0,
      0,0,0,0,0,0,0,
  ],
  "%": [
      8,
      1,1,1,0,0,0,1,1,
      1,1,1,0,0,1,1,1,
      1,1,1,0,1,1,1,0,
      0,0,0,1,1,1,0,0,
      0,0,1,1,1,0,0,0,
      0,1,1,1,0,1,1,1,
      1,1,1,0,0,1,1,1,
      1,1,0,0,0,1,1,1,
  ],
  ".": [
      2,
      0,0,
      0,0,
      0,0,
      0,0,
      0,0,
      0,0,
      1,1,
      1,1,
  ],
  "!": [
      2,
      1,1,
      1,1,
      1,1,
      1,1,
      1,1,
      0,0,
      1,1,
      1,1,
  ]
}

export default withBase(typeface);
