import Draw from "../draw.js";
import defaultColors from "../colors.js";
import alias from "../util/alias.js";
import subdivide from "../util/subdivide.js";
import { randomInt, randomBool } from "../util/index.js";

export default (typeface) => {
  return () => ({
    yUnits: 5,
    markFraction: 0.8,
    height: 0,
    // fillSpace: true,
    cache: {},
    glyphs: {},
    subdivide: 1,
    subdivideFns: {},
    alias: 8,
    letterSpacing: 1,
    invert: false,
    padding: 0,
    bgOpacity: 0,
    noise: 0,
    lineSpacing: 1,
    render: () => {
      console.log("Overwrite this function");
    },
    init({
      height,
      padding = 0,
      colors = defaultColors,
      draw,
      invert = false,
      noise = 0,
    }) {
      this.padding = padding;
      this.yUnits = this.yUnits + this.padding * 2;
      this.unitSize = height / this.yUnits;
      this.height = height;
      this.colors = colors;
      this.draw = draw;
      this.invert = invert;
      this.noise = noise;
      return this;
    },
    getWidth(content) {
      const lineWidths = content.split("\n").map((line) => {
        return (
          line.split("").reduce((a, b, index) => {
            const width = this.getGlyphWidth(b);
            return a + width + this.space(this.letterSpacing);
          }, 0) - this.space(this.letterSpacing)
        );
      });

      return lineWidths.sort((a, b) => a - b).reverse()[0];
    },
    getHeight(content) {
      const lineCount = content.split("\n").length;
      if (lineCount === 1) return this.height;
      const lineSpaces = lineCount - 1;
      return (
        lineCount * this.height +
        lineSpaces * (this.lineSpacing * this.unitSize)
      );
    },
    getGlyphWidth(glyph) {
      const data = this.glyphData(glyph);
      return data ? data.width : 0;
    },
    space(letterSpacing) {
      return this.unitSize * letterSpacing;
    },
    glyphData(glyph) {
      const data = this.glyphs[glyph] || null;
      if (!data) {
        console.warn(`Glyph doesn't exist '${glyph}'`);
      }
      let [xUnits, ...pattern] = data || [4];
      const { yUnits } = this;

      pattern = subdivide(pattern, xUnits, this.subdivide, this.subdivideFns);
      pattern = alias(xUnits * this.subdivide, pattern, this.alias);

      if (this.invert) {
        pattern = pattern.map((item) => (item ? 0 : 1));
      }

      if (this.padding) {
        pattern.push(
          ...new Array(xUnits * this.padding).fill(this.invert ? 1 : 0)
        );
        pattern.unshift(
          ...new Array(xUnits * this.padding).fill(this.invert ? 1 : 0)
        );
      }

      let noiseCount = randomInt(0, this.noise);

      while (noiseCount--) {
        pattern[randomInt(0, pattern.length - 1)] = randomBool() ? 1 : 0;
      }

      return {
        glyph,
        height: this.height,
        width: xUnits * this.unitSize,
        xUnits,
        yUnits,
        pattern,
        unitSize: this.unitSize,
      };
    },
    renderSpace(xUnits, offset) {
      const { unitSize, yUnits, invert, subDivide } = this;
      let pattern = new Array(yUnits * xUnits).fill(invert ? 1 : 0);
      pattern = subdivide(pattern, xUnits, this.subdivide);

      this.renderGlyph(
        {
          glyph: "space",
          height: this.height,
          width: xUnits * unitSize,
          xUnits,
          yUnits,
          unitSize,
          pattern,
        },
        offset
      );
    },
    renderGlyph(glyphData, offset, count = 0) {
      const { draw, cache } = this;
      const { width, height, glyph } = glyphData;

      const cacheKey = `${glyph}-${count}`;

      if (cache[cacheKey]) {
        const cachedCanvas = cache[cacheKey];
        draw.image({
          image: cachedCanvas,
          ...offset,
          width: cachedCanvas.width,
          height: cachedCanvas.height,
        });
        return;
      }
      const preRender = document.createElement("canvas");

      const ctx = preRender.getContext("2d");

      ctx.scale(devicePixelRatio, devicePixelRatio);

      preRender.width = Math.ceil(width) * devicePixelRatio;
      preRender.height = Math.ceil(height) * devicePixelRatio;

      const preDraw = Draw({ canvas: preRender });

      /* actual render function */

      this.render({
        preRender,
        preDraw,
        offset,
        ...glyphData,
      });

      /* here */

      cache[cacheKey] = preRender;

      draw.image({
        image: preRender,
        ...offset,
        width: preRender.width,
        height: preRender.height,
      });
    },
    subdivideFns: {
      2: (subdivisions) => {
        const arr = [];
        for (let i = 0; i < subdivisions; i++) {
          arr.push([
            ...new Array(subdivisions - i - 1).fill(0),
            ...new Array(i + 1).fill(2),
          ]);
        }
        return arr.flat();
      },
      3: (subdivisions) => {
        const arr = [];
        for (let i = 0; i < subdivisions; i++) {
          arr.push([
            ...new Array(i + 1).fill(3),
            ...new Array(subdivisions - i - 1).fill(0),
          ]);
        }
        return arr.flat();
      },
      4: (subdivisions) => {
        const arr = [];
        for (let i = 0; i < subdivisions; i++) {
          arr.push([
            ...new Array(subdivisions - i).fill(4),
            ...new Array(i).fill(0),
          ]);
        }
        return arr.flat();
      },
      5: (subdivisions) => {
        const arr = [];
        for (let i = 0; i < subdivisions; i++) {
          arr.push([
            ...new Array(i).fill(0),
            ...new Array(subdivisions - i).fill(5),
          ]);
        }
        return arr.flat();
      },
    },
    ...typeface,
  });
};
