export default `
precision highp float;

// Refs
uniform vec3 color;
uniform vec3 lightPosition;

uniform float ambientLightStrength;
uniform float minimalAmbientLight;

void main(void) {
    gl_FragColor = vec4(color, 1.);
}
`;
