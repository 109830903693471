"use strict";

export const RED = "#ff0000";
export const GREEN = "#00ff00";
export const BLUE = "#0000ff";
export const WHITE = "#ffffff";
export const BLACK = "#000000";
export const TWO_PI = Math.PI * 2;
export const HALF_PI = Math.PI / 2;
export const QUARTER_PI = Math.PI / 4;
export const PI = Math.PI;
export const OFFSET = 0.002;
