import { ShaderMaterial, Vector2, Texture, Color3 } from '@babylonjs/core';

const shaderMaterials = {};

export default function getShaderMaterial(scene, shader, data = {}) {
  const material = new ShaderMaterial(
    `${shader}Shader`,
    scene,
    {
      vertex: shader,
      fragment: shader,
    },
    {
      attributes: ['position', 'normal', 'uv', 'instanceOffset'],
      uniforms: [
        // "model",
        'world',
        // "worldView",
        'worldViewProjection',
        'viewProjection',
        'view',
        // "projection",
        ...(data.includeFog ? ['vFogInfos', 'vFogColor'] : []),
      ],
    }
  );

  const {
    ambientLightStrength,
    bumpMap,
    color,
    colors,
    lightPositions,
    lightColors,
    minimalAmbientLight,
    position,
    scale,
    texture,
    textureScale,
    includeFog,
  } = data;

  if (lightPositions) {
    material.setArray3('lightPositions', lightPositions);
    material.setInt('lightCount', lightPositions.length);
  }
  if (lightColors) {
    material.setColor3Array(
      'lightColors',
      lightColors.map(color => Color3.FromHexString(color))
    );
  }

  if (color) {
    material.setColor3('color', Color3.FromHexString(color));
  }

  if (colors) {
    material.setColor3Array(
      'colors',
      colors.map(color => Color3.FromHexString(color))
    );
  }
  if (minimalAmbientLight) {
    material.setFloat('minimalAmbientLight', minimalAmbientLight);
  }
  if (ambientLightStrength) {
    material.setFloat('ambientLightStrength', ambientLightStrength);
  }

  if (texture) {
    const t1 = new Texture(texture, scene);
    material.setTexture('t1', t1);
  }

  if (bumpMap) {
    const bump = new Texture(bumpMap, scene);
    material.setTexture('bump', bump);
  }

  if (position) {
    material.setVector2('textureOffset', new Vector2(position.u, position.v));
  }

  if (scale) {
    material.setVector2('textureScale', new Vector2(scale.u, scale.v));
  }

  if (includeFog) {
    material.onBind = mesh => {
      const effect = material.getEffect();
      effect.setFloat4(
        'vFogInfos',
        scene.fogMode,
        scene.fogStart,
        scene.fogEnd,
        scene.fogDensity
      );
      effect.setColor3('vFogColor', scene.fogColor);
    };
  }

  material.freeze();

  return material;
}
