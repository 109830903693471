import { flattenVertices } from "./vertices";

class MeshModel {
  constructor(data, opt = {}) {
    if (data) {
      this._data = data;
    } else {
      const { position, scale, rotation } = {
        position: { x: 0, y: 0, z: 0 },
        rotation: 0,
        scale: { x: 1, y: 1, z: 1 },
        ...opt,
      };
      this._data = {
        position,
        scale,
        rotation,
        vertices: [],
        indices: [],
        textures: [],
        textureFaces: [],
        lights: [],
        thrusters: [],
      };
    }
  }

  get shader() {
    return this._data.shader;
  }

  get textureShader() {
    return this._data.textureShader;
  }

  get lightIndices() {
    return this._data.lights;
  }

  get position() {
    return this._data.position;
  }

  get rotation() {
    return this._data.rotation;
  }

  get scale() {
    return this._data.scale;
  }

  get vertices() {
    return this._data.vertices;
  }

  get indices() {
    return this._data.indices;
  }

  get textureFaces() {
    return this._data.textureFaces;
  }

  _addToData(key, value) {
    this._data[key] = [...this._data[key], ...value];
  }

  _addVertices(vertices) {
    this._addToData("vertices", flattenVertices(vertices));
  }

  addVerticesAndUpdateIndices(vertices, indices) {
    indices = indices.map(v => v + this._data.vertices.length / 3);
    this._addVertices(vertices);
    return indices;
  }

  addVerticesAndIndices(vertices, indices) {
    indices = indices.map(v => v + this._data.vertices.length / 3);
    this._addVertices(vertices);
    this._addToData("indices", indices);
  }

  addLight({ vertices, indices }) {
    const newIndices = this.addVerticesAndUpdateIndices(vertices, indices);
    this._data.lights.push(...newIndices);
  }

  addLights(...args) {
    args.forEach(arg => this.addLight(arg));
  }

  addObject({ vertices, indices }, isLight = false) {
    this.addVerticesAndIndices(vertices, indices);
  }

  addTexture(texture) {
    this._data.textures.push(texture);
    return this._data.textures.length - 1;
  }

  addTextureFace(data) {
    this._data.textureFaces.push(data);
  }

  getTexture(idx) {
    return this._data.textures[idx];
  }

  exportJson() {}

  getData() {
    return this._data;
  }
}

["Vertices", "Indices", "Normals", "Uvs", "TexturePlanes"].forEach(s => {
  const key = s.toLowerCase();
  MeshModel.prototype[`set${s}`] = function (value) {
    this._data[key] = value;
  };
  MeshModel.prototype[`get${s}`] = function () {
    return this._data[key];
  };
});

export default MeshModel;
