import { Mesh, VertexData, Color4 } from "@babylonjs/core";

export function createShape(
  vertices,
  indices,
  normals = [],
  uvs = [],
  name = "",
  flatShading = true
) {
  const positions = vertices;

  const vertexData = new VertexData();
  vertexData.positions = positions;
  vertexData.indices = [...indices]; // Weird bug - this sorts the array

  if (!normals.length) {
    // VertexData.ComputeNormals(positions, indices, normals);
    // vertexData.normals = normals; //Assignment of normal to vertexData added
  }

  if (uvs.length) {
    vertexData.uvs = uvs;
  }
  const shape = new Mesh(name);
  vertexData.applyToMesh(shape);

  if (flatShading) {
    shape.convertToFlatShadedMesh();
  }

  return shape;
}

const EPSILON = 0.95;
const EDGE_WIDTH = 0.4;
const EDGE_COLOR = new Color4(0, 0, 0, 1);

export function showMeshEdges(mesh) {
  mesh.enableEdgesRendering(EPSILON);
  mesh.edgesWidth = EDGE_WIDTH;
  mesh.edgesColor = EDGE_COLOR;
}
