import { BlockModel, getWalls, flattenVertices, createShape } from "../util";
import { Mesh } from "@babylonjs/core";
import renderBlock from "./renderBlock";

// import "regenerator-runtime/runtime";

export default function renderRacer(racerData, scene) {
  const racerMesh = new Mesh(racerData.meta.name, scene);

  const blocks = racerData.blocks.map((block) => {
    const blockModel = new BlockModel(block);
    const blockData = renderBlock(blockModel, racerData, scene);
    blockData.mesh.parent = racerMesh;
    return blockData;
  });

  return { racerMesh, blocks };
}
