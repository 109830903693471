import { Vector3 } from "@babylonjs/core";

export function getVerticesFromIndices(indices, vertices) {
  return indices.map((indice) => vertices[indice]);
}

export function flattenVertices(vertices, twoD = false) {
  return vertices.reduce((a, b) => {
    return [...a, b.x, b.y, ...(twoD ? [] : [b.z])];
  }, []);
}

export function getNormal(vertices) {
  const vx = vertices[0].subtract(vertices[1]).normalize();
  const vy = vertices[0].subtract(vertices[3]).normalize();
  return Vector3.Cross(vx, vy).normalize();
}

export function v3ToObj(vertice) {
  const [x, y, z] = vertice.asArray();

  return {
    x,
    y,
    z,
  };
}
