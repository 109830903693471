import withBase from "./withBase.js";
import alias from "../util/alias.js";
import subdivide from "../util/subdivide.js";

const showGuides = false;

const typeface = {
  yUnits: 8,
  markFraction: 0.9,
  subdivide: 3,
  alias: 6,
  noise: 12,
  letterSpacing: 0.25,
  lineSpacing: 0.25,
  subdivideFns: {
    2: (subdivisions) => {
      const arr = [];
      for (let i = 0; i < subdivisions; i++) {
        arr.push([
          ...new Array(subdivisions - i - 1).fill(0),
          ...new Array(i + 1).fill(2),
        ]);
      }
      return arr.flat();
    },
    3: (subdivisions) => {
      const arr = [];
      for (let i = 0; i < subdivisions; i++) {
        arr.push([
          ...new Array(i + 1).fill(3),
          ...new Array(subdivisions - i - 1).fill(0),
        ]);
      }
      return arr.flat();
    },
    4: (subdivisions) => {
      const arr = [];
      for (let i = 0; i < subdivisions; i++) {
        arr.push([
          ...new Array(subdivisions - i).fill(4),
          ...new Array(i).fill(0),
        ]);
      }
      return arr.flat();
    },
    5: (subdivisions) => {
      const arr = [];
      for (let i = 0; i < subdivisions; i++) {
        arr.push([
          ...new Array(i).fill(0),
          ...new Array(subdivisions - i).fill(5),
        ]);
      }
      return arr.flat();
    },
  },
  render({ xUnits, yUnits, width, unitSize, pattern, preDraw, offset }) {
    const segmentSize = unitSize / this.subdivide;

    for (let i = 0; i < yUnits * this.subdivide; i++) {
      const y = i * segmentSize;
      for (let j = 0; j < xUnits * this.subdivide; j++) {
        const x = j * segmentSize;

        const innerSize = segmentSize * this.markFraction;
        const shouldDraw = pattern[i * (xUnits * this.subdivide) + j];
        if (shouldDraw) {
          if (shouldDraw > 1) {
            const points = {
              2: [
                x + innerSize,
                y,
                x + innerSize,
                y + innerSize,
                x,
                y + innerSize,
              ],
              3: [x, y, x + innerSize, y + innerSize, x, y + innerSize],
              4: [x, y, x + innerSize, y, x, y + innerSize],
              5: [x, y, x + innerSize, y, x + innerSize, y + innerSize],
            }[shouldDraw];
            preDraw.shape({
              points,
              fill: this.colors.primary,
            });
          }
          if (shouldDraw === 1) {
            preDraw.rectangle({
              x,
              y,
              width: innerSize,
              height: innerSize,
              // cornerRadius: segmentSize / 4,
              fill: this.colors.primary,
              opacity: shouldDraw ? 1 : 0,
            });
          }
        }
      }
    }
  },
};

/* prettier-ignore */
typeface.glyphs =  {
  " ": [4],
  "A": [
      5,
      2,1,1,1,3,
      1,1,1,1,1,
      1,1,0,1,1,
      1,1,1,1,1,
      1,1,1,1,1,
      1,1,0,1,1,
      1,1,0,1,1,
      1,1,0,1,1,
  ],
  "B": [
      5,
      1,1,1,1,3,
      1,1,1,1,1,
      1,1,0,1,1,
      1,1,1,1,4,
      1,1,1,1,3,
      1,1,0,1,1,
      1,1,1,1,1,
      1,1,1,1,4,
  ],
  "C": [
      6,
      2,1,1,1,1,1,
      1,1,1,1,1,1,
      1,1,0,0,0,0,
      1,1,0,0,0,0,
      1,1,0,0,0,0,
      1,1,0,0,0,0,
      1,1,1,1,1,1,
      5,1,1,1,1,1,
  ],
  "D": [
      7,
      1,1,1,1,1,1,3,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,4,
  ],
  "E": [
      6,
      1,1,1,1,1,1,
      1,1,1,1,1,1,
      1,1,0,0,0,0,
      1,1,1,1,1,0,
      1,1,1,1,1,0,
      1,1,0,0,0,0,
      1,1,1,1,1,1,
      1,1,1,1,1,1,
  ],
  "F": [
      7,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,
      1,1,1,1,1,1,0,
      1,1,1,1,1,1,0,
      1,1,0,0,0,0,0,
      1,1,0,0,0,0,0,
      1,1,0,0,0,0,0,
  ],
  "G": [
      7,
      2,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,
      1,1,0,1,1,1,1,
      1,1,0,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,1,
      5,1,1,1,1,1,1,
  ],
  "H": [
      7,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
  ],
  "I": [
      6,
      1,1,1,1,1,1,
      1,1,1,1,1,1,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      1,1,1,1,1,1,
      1,1,1,1,1,1,
  ],
  "J": [
      6,
      0,0,0,0,1,1,
      0,0,0,0,1,1,
      0,0,0,0,1,1,
      0,0,0,0,1,1,
      0,0,0,0,1,1,
      0,0,0,0,1,1,
      1,1,1,1,1,1,
      1,1,1,1,1,4,
  ],
  "K": [
      8,
      1,1,0,0,0,0,1,1,
      1,1,0,0,0,0,1,1,
      1,1,0,0,0,2,1,1,
      1,1,1,1,1,1,1,4,
      1,1,1,1,1,1,1,3,
      1,1,0,0,0,5,1,1,
      1,1,0,0,0,0,1,1,
      1,1,0,0,0,0,1,1,
  ],
  "L": [
      6,
      1,1,0,0,0,0,
      1,1,0,0,0,0,
      1,1,0,0,0,0,
      1,1,0,0,0,0,
      1,1,0,0,0,0,
      1,1,0,0,0,0,
      1,1,1,1,1,1,
      5,1,1,1,1,1,
  ],
  "M": [
      8,
      1,1,0,0,0,0,1,1,
      1,1,3,0,0,2,1,1,
      1,1,1,3,2,1,1,1,
      1,1,5,1,1,4,1,1,
      1,1,0,5,4,0,1,1,
      1,1,0,0,0,0,1,1,
      1,1,0,0,0,0,1,1,
      1,1,0,0,0,0,1,1,
  ],
  "N": [
      7,
      1,1,0,0,0,1,1,
      1,1,3,0,0,1,1,
      1,1,1,3,0,1,1,
      1,1,5,1,3,1,1,
      1,1,0,5,1,1,1,
      1,1,0,0,5,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
  ],
  "O": [
      7,
      2,1,1,1,1,1,3,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,1,
      5,1,1,1,1,1,4,
  ],
  "P": [
      7,
      1,1,1,1,1,1,3,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,4,
      1,1,0,0,0,0,0,
      1,1,0,0,0,0,0,
      1,1,0,0,0,0,0,
  ],
  "Q": [
      7,
      2,1,1,1,1,1,3,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,1,1,0,
      1,1,1,1,1,1,1,
      5,1,1,1,0,1,1,
  ],
  "R": [
      7,
      1,1,1,1,1,1,3,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,4,
      1,1,1,1,1,1,3,
      1,1,0,0,5,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
  ],
  "S": [
      7,
      2,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,
      1,1,1,1,1,1,1,
      5,1,1,1,1,1,1,
      0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,4,
  ],
  "T": [
      6,
      1,1,1,1,1,1,
      1,1,1,1,1,1,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
  ],
  "U": [
      7,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,1,1,1,1,1,
      5,1,1,1,1,1,4,
  ],
  "V": [
      7,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,
      1,1,1,0,1,1,1,
      0,1,1,1,1,1,0,
      0,0,1,1,1,0,0,
  ],
  "W": [
      8,
      1,1,0,0,0,0,1,1,
      1,1,0,0,0,0,1,1,
      1,1,0,0,0,0,1,1,
      1,1,0,2,3,0,1,1,
      1,1,2,1,1,3,1,1,
      1,1,1,4,5,1,1,1,
      1,1,4,0,0,5,1,1,
      1,1,0,0,0,0,1,1,
  ],
  "X": [
      8,
      1,2,0,0,0,0,2,1,
      5,1,3,0,0,2,1,4,
      0,5,1,3,2,1,4,0,
      0,0,5,1,1,4,0,0,
      0,0,2,1,1,3,0,0,
      0,2,1,4,5,1,3,0,
      2,1,4,0,0,5,1,3,
      1,4,0,0,0,0,5,1,
  ],
  "Y": [
      6,
      1,1,0,0,1,1,
      1,1,0,0,1,1,
      1,1,0,0,1,1,
      5,1,3,2,1,4,
      0,5,1,1,4,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
  ],
  "Z": [
      7,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
      0,0,0,0,1,1,1,
      0,0,0,2,1,4,0,
      0,2,1,4,0,0,0,
      1,1,1,0,0,0,0,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
  ],
  "0": [
      7,
      0,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,
      1,1,1,0,0,1,1,
      1,1,0,1,0,1,1,
      1,1,0,0,1,1,1,
      1,1,1,1,1,1,1,
      0,1,1,1,1,1,0,
  ],
  "1": [
      6,
      0,1,1,1,0,0,
      1,1,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      0,0,1,1,0,0,
      1,1,1,1,1,1,
      1,1,1,1,1,1,
  ],
  "2": [
      7,
      0,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      0,0,0,0,1,1,1,
      0,0,1,1,1,1,1,
      0,1,1,1,1,0,0,
      1,1,1,0,0,0,0,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
  ],
  "3": [
      7,
      0,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      0,0,0,0,1,1,1,
      0,0,1,1,1,1,0,
      0,0,1,1,1,1,0,
      0,0,0,0,1,1,1,
      1,1,1,1,1,1,1,
      0,1,1,1,1,1,0,
  ],
  "4": [
      8,
      0,0,0,0,1,1,1,0,
      0,0,0,1,1,1,1,0,
      0,0,1,1,1,1,1,0,
      0,1,1,1,0,1,1,0,
      1,1,1,0,0,1,1,0,
      1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,
      0,0,0,0,1,1,1,0,
  ],
  "5": [
      7,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,
      1,1,1,1,1,1,0,
      0,1,1,1,1,1,1,
      0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,
      0,1,1,1,1,1,0,
  ],
  "6": [
      7,
      0,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,
      1,1,0,1,1,1,1,
      1,1,1,1,1,1,1,
      1,1,1,0,0,1,1,
      1,1,1,1,1,1,1,
      0,1,1,1,1,1,0,
  ],
  "7": [
      7,
      1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,
      0,0,0,0,1,1,1,
      0,0,0,1,1,1,0,
      0,0,1,1,1,0,0,
      0,1,1,1,0,0,0,
      0,1,1,1,0,0,0,
      0,1,1,1,0,0,0,
  ],
  "8": [
      8,
      0,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,
      1,1,1,0,0,1,1,1,
      0,1,1,1,1,1,1,0,
      0,1,1,1,1,1,1,0,
      1,1,1,0,0,1,1,1,
      1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,0,
  ],
  "9": [
      7,
      0,1,1,1,1,1,0,
      1,1,1,1,1,1,1,
      1,1,0,0,1,1,1,
      1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,
      0,0,0,0,0,1,1,
      0,0,0,0,1,1,1,
      0,0,0,1,1,1,0,
  ],
  "_": [
      4,
      1,1,1,1,
      1,0,0,1,
      1,0,0,1,
      1,0,0,1,
      1,0,0,1,
      1,0,0,1,
      1,0,0,1,
      1,0,0,1,
  ],
  "-": [
      4,
      0,0,0,0,
      0,0,0,0,
      0,0,0,0,
      1,1,1,1,
      1,1,1,1,
      0,0,0,0,
      0,0,0,0,
      0,0,0,0,
  ],
  "+": [
      3,
      1,0,1,
      1,0,1,
      1,0,1,
      1,1,1,
      0,1,0,
      0,1,0,
      0,1,0,
      0,1,0,
  ],
  "#": [
      7,
      0,0,0,0,0,0,0,
      0,1,1,0,1,1,0,
      1,1,1,1,1,1,1,
      0,1,1,0,1,1,0,
      0,1,1,0,1,1,0,
      1,1,1,1,1,1,1,
      0,1,1,0,1,1,0,
      0,0,0,0,0,0,0,
  ],
  "%": [
      8,
      1,1,1,0,0,0,1,1,
      1,1,1,0,0,1,1,1,
      1,1,1,0,1,1,1,0,
      0,0,0,1,1,1,0,0,
      0,0,1,1,1,0,0,0,
      0,1,1,1,0,1,1,1,
      1,1,1,0,0,1,1,1,
      1,1,0,0,0,1,1,1,
  ],
  ".": [
      2,
      0,0,
      0,0,
      0,0,
      0,0,
      0,0,
      0,0,
      1,1,
      1,1,
  ],
  "!": [
      2,
      1,1,
      1,1,
      1,1,
      1,1,
      1,1,
      0,0,
      1,1,
      1,1,
  ]
}

export default withBase(typeface);
