import {
  randomInt,
  randomRotation,
  randomPosition,
  random,
  instanceShader,
} from "@blockracers/util";

import { Mesh, Vector3 } from "@babylonjs/core";

export default function(scene, color) {
  /* prettier-ignore */
  const vertices = [
      0,0,0,
      0,0,1,
      1,0,1,
      1,0,0
  ];
  /* prettier-ignore */
  const indices = [0,1,2];

  const envMesh = new Mesh();

  const mesh = Mesh.CreatePlane("plane", 0.4, scene, false, Mesh.DOUBLESIDE);

  mesh.material = instanceShader(scene, color);
  mesh.parent = envMesh;
  // mesh.edgesShareWithInstances = true;

  const positionLimit = 30;

  randomRotation(mesh);
  randomPosition(mesh, positionLimit, [0, positionLimit], positionLimit);

  // const instanceCount = randomInt(120, 600);
  const instanceCount = 2000;

  for (let i = 0; i < instanceCount; i++) {
    const instance = mesh.createInstance("i" + i);
    const scale = random(0.04, 0.3);
    instance.scaling = new Vector3(scale, scale, scale);

    randomRotation(instance);
    randomPosition(instance, positionLimit, [0, positionLimit], positionLimit);
  }

  mesh.scaling = new Vector3(0.1, 0.1, 0.1);

  return envMesh;
}
