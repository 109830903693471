export default `
precision highp float;

// Lights
varying vec3 vPositionW;
varying vec3 vNormalW;
varying vec2 vUV;

// Refs
uniform vec3 lightPositions[2];
uniform vec3 lightColors[2];
uniform float ambientLightStrength;
uniform float minimalAmbientLight;
uniform vec3 color;

void main(void) {

    // Lighting
    float diffuse = 0.;
    vec3 lightColor;

    // diffuse
    for (int i = 0; i < 2; i++) {
        vec3 lightDir = normalize(lightPositions[i] - vPositionW);
        lightColor += max(dot(vNormalW, lightPositions[i]), 0.0) * lightColors[i];

        float ndl = max(dot(vNormalW, lightDir), 0.0);
        ndl *= ambientLightStrength;
        diffuse += ndl; 
    }

    vec3 colorWithLighting = max(minimalAmbientLight, diffuse) * color;

    gl_FragColor = vec4(colorWithLighting, 1.);
}`;
