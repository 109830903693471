import withBase from "./withBase.js";
import alias from "../util/alias.js";
import subdivide from "../util/subdivide.js";

const typeface = {
  yUnits: 7,
  kerningUnit: 1,
  markFraction: 0.8,
  subdivide: 1,
  render({ xUnits, yUnits, width, unitSize, pattern, preDraw, offset }) {
    const segmentSize = unitSize / this.subdivide;
    for (let i = 0; i < yUnits * this.subdivide; i++) {
      const y = i * segmentSize + segmentSize / 2;
      for (let j = 0; j < xUnits * this.subdivide; j++) {
        const x = j * segmentSize + segmentSize / 2;
        preDraw.circle({
          x,
          y,
          radius: segmentSize * (this.markFraction / 2),
          fill: pattern[i * (xUnits * this.subdivide) + j]
            ? this.colors.primary
            : null,
          opacity: pattern[i * (xUnits * this.subdivide) + j] ? 1 : 0.3,
        });
      }
    }
  },
};

/* prettier-ignore */
typeface.glyphs =  {
  " ": [4],
  "-": [
    5,
    0,0,0,0,0,
    0,0,0,0,0,
    0,0,0,0,0,
    1,1,1,1,1,
    0,0,0,0,0,
    0,0,0,0,0,
    0,0,0,0,0,
  ],
  ".": [
    1,
    0,
    0,
    0,
    0,
    0,
    0,
    1,
  ],
  "A": [
    4,
    2,1,1,3,
    1,0,0,1,
    1,0,0,1,
    1,1,1,1,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
  ],
  "C": [
    4,
    2,1,1,3,
    1,0,0,1,
    1,0,0,0,
    1,0,0,0,
    1,0,0,0,
    1,0,0,1,
    5,1,1,4,
  ],
  "D": [
    4,
    1,1,1,3,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
    1,1,1,4,
  ],
  "E": [
    4,
    1,1,1,1,
    1,0,0,0,
    1,0,0,0,
    1,1,1,0,
    1,0,0,0,
    1,0,0,0,
    1,1,1,1,
  ],
  "F": [
    4,
    1,1,1,1,
    1,0,0,0,
    1,0,0,0,
    1,1,1,0,
    1,0,0,0,
    1,0,0,0,
    1,0,0,0,
  ],
  "G": [
    4,
    2,1,1,1,
    1,0,0,0,
    1,0,0,0,
    1,0,1,1,
    1,0,0,1,
    1,0,0,1,
    5,1,1,1,
  ],
  "H": [
    5,
    1,0,0,0,1,
    1,0,0,0,1,
    1,0,0,0,1,
    1,1,1,1,1,
    1,0,0,0,1,
    1,0,0,0,1,
    1,0,0,0,1,
  ],
  "I": [
    3,
    1,1,1,
    0,1,0,
    0,1,0,
    0,1,0,
    0,1,0,
    0,1,0,
    1,1,1,
  ],
  "J": [
    4,
    0,0,0,1,
    0,0,0,1,
    0,0,0,1,
    0,0,0,1,
    0,0,0,1,
    0,0,0,1,
    1,1,1,4,
  ],
  "M": [
    6,
    1,0,0,0,0,1,
    1,1,0,0,1,1,
    1,0,1,1,0,1,
    1,0,0,0,0,1,
    1,0,0,0,0,1,
    1,0,0,0,0,1,
    1,0,0,0,0,1,
  ],
  "N": [
    4,
    1,0,0,1,
    1,3,0,1,
    1,5,3,1,
    1,0,5,1,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
  ],
  "O": [
    4,
    2,1,1,3,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
    5,1,1,4,
  ],
  "P": [
    4,
    1,1,1,3,
    1,0,0,1,
    1,0,0,1,
    1,1,1,4,
    1,0,0,0,
    1,0,0,0,
    1,0,0,0,
  ],
  "R": [
    4,
    1,1,1,3,
    1,0,0,1,
    1,0,0,1,
    1,1,1,4,
    1,0,0,3,
    1,0,0,1,
    1,0,0,1,
  ],
  "S": [
    4,
    2,1,1,1,
    1,0,0,0,
    1,0,0,0,
    5,1,1,3,
    0,0,0,1,
    0,0,0,1,
    1,1,1,4,
  ],
  "T": [
    3,
    1,1,1,
    0,1,0,
    0,1,0,
    0,1,0,
    0,1,0,
    0,1,0,
    0,1,0,
  ],
  "U": [
    4,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
    1,0,0,1,
    5,1,1,4,
  ],
  "Y": [
    3,
    1,0,1,
    1,0,1,
    1,0,1,
    5,1,4,
    0,1,0,
    0,1,0,
    0,1,0,
  ],
  "a": [
    5,
    0,0,0,0,0,
    0,0,0,0,0,
    0,0,1,1,0,
    0,1,0,0,1,
    1,0,0,0,1,
    1,0,0,1,1,
    0,1,1,0,1,
  ],
  "b": [
    5,
    1,0,0,0,0,
    1,0,0,0,0,
    1,0,1,1,0,
    1,1,0,0,1,
    1,0,0,0,1,
    1,0,0,0,1,
    0,1,1,1,0,
  ],
  "c": [
    5,
    0,0,0,0,0,
    0,0,0,0,0,
    0,1,1,1,0,
    1,0,0,0,1,
    1,0,0,0,0,
    1,0,0,0,1,
    0,1,1,1,0,
  ],
  "d": [
    5,
    0,0,0,0,1,
    0,0,0,0,1,
    0,1,1,0,1,
    1,0,0,1,1,
    1,0,0,0,1,
    1,0,0,1,1,
    0,1,1,0,1,
  ],
  "e": [
    5,
    0,0,0,0,0,
    0,0,0,0,0,
    0,1,1,1,0,
    1,0,0,0,1,
    1,1,1,1,0,
    1,0,0,0,0,
    0,1,1,1,0,
  ],
  "f": [
    4,
    0,0,1,1,
    0,1,0,0,
    1,1,1,0,
    0,1,0,0,
    0,1,0,0,
    0,1,0,0,
    0,1,0,0,
  ],
  "g": [
    5,
    0,0,0,0,0,
    0,0,0,0,0,
    0,1,1,1,0,
    1,0,0,0,1,
    1,0,0,0,1,
    1,0,0,1,1,
    0,1,1,0,1,
    0,0,0,0,1,
    0,1,1,1,0,
  ],
  "h": [
    5,
    1,0,0,0,0,
    1,0,0,0,0,
    1,0,1,1,0,
    1,1,0,0,1,
    1,0,0,0,1,
    1,0,0,0,1,
    1,0,0,0,1,
  ],
  "i": [
    2,
    0,0,
    1,0,
    0,0,
    1,0,
    1,0,
    1,0,
    0,1,
  ],
  "j": [
    2,
    0,0,
    0,1,
    0,0,
    0,1,
    0,1,
    0,1,
    0,1,
    0,1,
    1,0,
  ],
  "k": [
    5,
    1,0,0,0,0,
    1,0,0,0,0,
    1,0,1,1,0,
    1,1,0,0,1,
    1,0,1,1,0,
    1,0,0,1,0,
    1,0,0,0,1,
  ],
  "l": [
    2,
    1,0,
    1,0,
    1,0,
    1,0,
    1,0,
    1,0,
    0,1,
  ],
  "m": [
    8,
    0,0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,0,
    1,0,1,1,0,1,1,0,
    1,1,0,0,1,0,0,1,
    1,0,0,0,1,0,0,1,
    1,0,0,0,1,0,0,1,
    1,0,0,0,1,0,0,1,
  ],
  "n": [
    5,
    0,0,0,0,0,
    0,0,0,0,0,
    1,0,1,1,0,
    1,1,0,0,1,
    1,0,0,0,1,
    1,0,0,0,1,
    1,0,0,0,1,
  ],
  "o": [
    5,
    0,0,0,0,0,
    0,0,0,0,0,
    0,1,1,1,0,
    1,0,0,0,1,
    1,0,0,0,1,
    1,0,0,0,1,
    0,1,1,1,0,
  ],
  "p": [
    5,
    0,0,0,0,0,
    0,0,0,0,0,
    0,1,1,1,0,
    1,0,0,0,1,
    1,0,0,0,1,
    1,1,0,0,1,
    1,0,1,1,0,
    1,0,0,0,0,
    1,0,0,0,0,
  ],
  "q": [
    5,
    0,0,0,0,0,
    0,0,0,0,0,
    0,1,1,1,0,
    1,0,0,0,1,
    1,0,0,0,1,
    1,0,0,1,1,
    0,1,1,1,0,
    0,0,0,1,1,
    0,0,0,1,0,
  ],
  "r": [
    4,
    0,0,0,0,
    0,0,0,0,
    0,1,1,1,
    1,0,0,0,
    1,0,0,0,
    1,0,0,0,
    1,0,0,0,
  ],
  "s": [
    5,
    0,0,0,0,0,
    0,0,0,0,0,
    0,1,1,1,1,
    1,0,0,0,0,
    0,1,1,1,0,
    0,0,0,0,1,
    1,1,1,1,0,
  ],
  "t": [
    4,
    0,1,0,0,
    0,1,0,0,
    1,1,1,0,
    0,1,0,0,
    0,1,0,0,
    0,1,0,0,
    0,0,1,1,
  ],
  "u": [
    5,
    0,0,0,0,0,
    0,0,0,0,0,
    1,0,0,0,1,
    1,0,0,0,1,
    1,0,0,0,1,
    1,0,0,1,1,
    0,1,1,0,1,
  ],
  "v": [
    5,
    0,0,0,0,0,
    0,0,0,0,0,
    1,0,0,0,1,
    1,0,0,0,1,
    1,0,0,0,1,
    0,1,0,1,0,
    0,0,1,0,0,
  ],
  "w": [
    7,
    0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,
    1,0,0,1,0,0,1,
    1,0,0,1,0,0,1,
    1,0,0,1,0,0,1,
    1,0,0,1,0,0,1,
    0,1,1,0,1,1,0,
  ],
  "x": [
    5,
    0,0,0,0,0,
    0,0,0,0,0,
    1,0,0,0,1,
    0,1,0,1,0,
    0,0,1,0,0,
    0,1,0,1,0,
    1,0,0,0,1,
  ],
  "y": [
    5,
    0,0,0,0,0,
    0,0,0,0,0,
    1,0,0,0,1,
    1,0,0,0,1,
    1,0,0,0,1,
    1,0,0,1,1,
    0,1,1,0,1,
    0,0,0,0,1,
    0,1,1,1,0,
  ],
  "z": [
    4,
    0,0,0,0,
    0,0,0,0,
    1,1,1,1,
    0,0,0,1,
    0,1,1,0,
    1,0,0,0,
    1,1,1,1,
  ],
  ":": [
    1,
    0,
    1,
    1,
    0,
    1,
    1,
    0,
  ],
  "3": [
    4,
    1,1,1,1,
    0,0,0,1,
    0,0,0,4,
    0,1,1,0,
    0,0,0,3,
    0,0,0,1,
    1,1,1,4,
  ],

};

export default withBase(typeface);
