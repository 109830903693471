import colors from "./colors.js";
// import root from "window-or-global";
const root = window;

const devicePixelRatio = root.devicePixelRatio || 1;

function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `rgb(${r},${g},${b},${alpha})`;
}

function resizeCanvas(canvas) {
  const { innerWidth, innerHeight } = root || {};
  canvas.width = innerWidth * devicePixelRatio;
  canvas.height = innerHeight * devicePixelRatio;
  if (canvas.style) {
    canvas.style.width = `${innerWidth}px`;
    canvas.style.height = `${innerHeight}px`;
  }
}

const sizeCanvas = (canvas, width, height) => {
  canvas.width = width * devicePixelRatio;
  canvas.height = height * devicePixelRatio;
  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;
};

const sizes = {
  A4: {
    width: 2480,
    height: 3508,
  },
};

export default ({
  canvas,
  offscreen,
  container,
  withResize,
  width,
  height,
  background,
  size,
} = {}) => {
  if (!canvas) {
    canvas = document.createElement("canvas");
    if (background) {
      canvas.style.backgroundColor = background;
    }
    canvas.id = "canvas";
    if (!offscreen) {
      (container || document.body).prepend(canvas);
    }
  }

  if ((!size && !width && !height) || (width === true && !height)) {
    resizeCanvas(canvas);
  } else if (size) {
    if (!sizes[size]) {
      throw Error("Invalid size");
    }
    const { width: sizeWidth, height: sizeHeight } = sizes[size];
    sizeCanvas(canvas, sizeWidth, sizeHeight);
  } else {
    sizeCanvas(canvas, width, height);
  }

  // if (fitToScreen) {
  // }

  canvas.style.maxWidth = "90%";
  canvas.style.maxHeight = "90%";

  const ctx = canvas.getContext("2d");

  ctx.scale(devicePixelRatio, devicePixelRatio);

  const strokeShape = (stroke, opacity = 1) => {
    ctx.strokeStyle = !isNaN(opacity) ? hexToRGB(stroke, opacity) : stroke;
    ctx.stroke();
  };

  const fillShape = (fill, opacity = 1) => {
    ctx.fillStyle = !isNaN(opacity) ? hexToRGB(fill, opacity) : fill;
    ctx.fill();
  };

  const Draw = {
    canvas,
    ctx,
    image({
      x = 0,
      y = 0,
      srcX = 0,
      srcY = 0,
      srcWidth,
      srcHeight,
      image,
      width,
      height,
      opacity = 1,
    }) {
      const { globalAlpha: previousAlpha } = ctx;
      ctx.globalAlpha = opacity;

      ctx.drawImage(
        image,
        srcX,
        srcY,
        srcWidth ? srcWidth : width,
        srcHeight ? srcHeight : height,
        x,
        y,
        width / devicePixelRatio,
        height / devicePixelRatio
      );
      ctx.globalAlpha = previousAlpha;
    },
    addCanvas({ canvas, x = 0, y = 0 }) {
      ctx.drawImage(
        canvas,
        0,
        0,
        canvas.width,
        canvas.height,
        x,
        y,
        canvas.width / devicePixelRatio / 1,
        canvas.height / devicePixelRatio / 10
      );
    },
    clear({ x, y, width, height } = { x: 0, y: 0 }) {
      width = width || canvas.width;
      height = height || canvas.height;
      ctx.clearRect(x, y, width, height);
    },
    translate({ x, y }) {
      ctx.translate(x, y);
    },
    rotate({ angle }) {
      ctx.rotate(angle);
    },
    line({ x1, y1, x2, y2 }) {
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
    },
    circle({ x, y, width, height, stroke, fill, radius }) {
      ctx.arc(x, y, radius, 0, Math.PI * 2);
    },
    rectangle({ x, y, width, height, stroke, fill, cornerRadius }) {
      if (!cornerRadius) {
        ctx.rect(x, y, width, height);
      } else {
        ctx.moveTo(x, y + cornerRadius);
        ctx.arcTo(x, y, x + cornerRadius, y, cornerRadius);
        ctx.lineTo(x + width - cornerRadius, y);
        ctx.arcTo(x + width, y, x + width, y + cornerRadius, cornerRadius);
        ctx.lineTo(x + width, y + height - cornerRadius);
        ctx.arcTo(
          x + width,
          y + height,
          x + width - cornerRadius,
          y + height,
          cornerRadius
        );
        ctx.lineTo(x + cornerRadius, y + height);
        ctx.arcTo(x, y + height, x, y + height - cornerRadius, cornerRadius);
        ctx.lineTo(x, y + cornerRadius);
      }
    },
    shape({ points, fill }) {
      ctx.moveTo(points[0], points[1]);
      for (let i = 2; i < points.length; i += 2) {
        ctx.lineTo(points[i], points[i + 1]);
      }
      ctx.lineTo(points[0], points[1]);
    },
  };

  let handler = {
    get(target, propKey, receiver) {
      const origMethod = target[propKey];
      // console.log(propKey, origMethod, target);
      if (typeof origMethod != "function") {
        return origMethod;
      }
      return function (args) {
        if (["circle", "rectangle", "line", "shape"].includes(propKey)) {
          ctx.beginPath();
        }
        let result;
        if (args) {
          if (args.strokeWeight) {
            ctx.lineWidth = args.strokeWeight;
          }
        }
        if (origMethod) {
          result = origMethod.call(this, args);
        }
        if (args) {
          if (args.stroke) {
            strokeShape(args.stroke, args.opacity);
          }

          if (args.fill) {
            fillShape(args.fill, args.opacity);
          }
          if (args.closed) {
            ctx.closePath();
          }
          if (args.strokeWeight) {
            // restor default
            ctx.lineWidth = 1;
          }
        }
        return result;
      };
    },
  };

  const ProxyDraw = new Proxy(Draw, handler);

  if (withResize) {
    let timeout = false;
    const delay = 250;

    console.log("Adding resize event handler");

    root.addEventListener("resize", () => {
      clearTimeout(timeout);
      // start timing for event "completion"
      timeout = setTimeout(() => {
        console.log("Resizing canvas");
        resizeCanvas(canvas);
        ctx.scale(devicePixelRatio, devicePixelRatio);
        console.log("Calling resize fn");
        ProxyDraw.onResize && ProxyDraw.onResize();
      }, delay);
    });
  }

  return ProxyDraw;
};
