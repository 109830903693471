import {
  ArcRotateCamera,
  Color3,
  Color4,
  DirectionalLight,
  Effect,
  Engine,
  GlowLayer,
  //  HemisphericLight,
  // Mesh,
  MeshBuilder,
  // Ray,
  Scene,
  // SceneInstrumentation,
  ShadowGenerator,
  // SolidParticleSystem,
  // StandardMaterial,
  // Texture,
  Vector3,
} from '@babylonjs/core';

import { loadDefaultShaders, shaderVars, createSkybox } from '../materials';

const $ = document.querySelector.bind(document);

// Flags

export default ({
  canvas,
  clearColor = '#dddddd',
  shadowsEnabled = false,
  runInRenderLoop = () => {},
  camera: cameraArg,
  cameraTarget = Vector3.Zero(),
  cameraLimit = true,
  fogEnabled = false,
  fogColor = '#ff0000',
  fogStart = 600.0,
  fogEnd = 700.0,
  engineOptions = {},
  skyboxEnabled = true,
}) => {
  const engine = new Engine(canvas, true, engineOptions, true);
  const scene = new Scene(engine);

  // scene.clearColor = clearColor;
  scene.clearColor = Color3.FromHexString(clearColor);
  scene.shadowsEnabled = shadowsEnabled;

  if (fogEnabled) {
    console.log('Fog enabled');
    scene.fogMode = Scene.FOGMODE_LINEAR;
    // scene.fogMode = Scene.FOGMODE_EXP;
    // scene.fogDensity = 0.05;
    scene.fogStart = fogStart;
    scene.fogEnd = fogEnd;
    scene.fogColor = Color3.FromHexString(fogColor);
  }

  if (skyboxEnabled) {
    // MeshBuilder.CreateSphere(
    //   "skyBox",
    //   {
    //     segments: 50,
    //     diameter: 10000,
    //   },
    //   scene
    // );
    // createSkybox(scene, colors);
  }

  // const gl = new GlowLayer("glow", scene, {
  //   mainTextureSamples: 4,
  // });

  // const ambientLight = new HemisphericLight(
  //   "Ambient Light",
  // new Vector3(-1, 1, 0),
  // scene
  // );

  // const directionalLight = new DirectionalLight(
  //   "Directional Light",
  //   new Vector3(1, -1, 0),
  //   scene
  // );

  // AxisHelper(scene);
  loadDefaultShaders(Effect);

  const camera =
    (cameraArg && cameraArg(scene)) ||
    new ArcRotateCamera(
      'Camera',
      -Math.PI / 2,
      Math.PI / 4,
      50,
      cameraTarget,
      scene
    );

  if (!cameraArg) {
    camera.attachControl(canvas, true);
    camera.wheelPrecision = 50;

    if (cameraLimit) {
      camera.upperBetaLimit = Math.PI / 2;
      camera.lowerBetaLimit = 0;
      camera.panningSensibility = 0;
      camera.lowerRadiusLimit = camera.radius;
      camera.upperRadiusLimit = camera.radius;
    }
  }

  const shadowGenerators = [];
  const lights = [];

  for (let i = 0; i < shaderVars.lightPositions.length; i += 3) {
    const position = new Vector3(
      shaderVars.lightPositions[i],
      shaderVars.lightPositions[i + 1],
      shaderVars.lightPositions[i + 2]
    );

    var light = new DirectionalLight('dir01', position.negate(), scene);

    light.position = position;

    light.intensity = shaderVars.ambientLightStrength;

    light.autoUpdateExtends = true;

    lights.push(light);
    light.shadowMinZ = 10;
    light.shadowMaxZ = 70;

    const shadowGenerator = new ShadowGenerator(1024, light);

    shadowGenerator.useExponentialShadowMap = false;
    shadowGenerator.useBlurExponentialShadowMap = true;
    shadowGenerator.blurScale = 0.5;
    shadowGenerator.blurBoxOffset = 4;
    shadowGenerator.usePercentageCloserFiltering = false; //v3.2, webgl2 shadows-.
    shadowGenerator.filteringQuality = ShadowGenerator.QUALITY_LOW;
    shadowGenerator.useContactHardeningShadow = false;

    // shadowGenerator.usePoissonSampling = true;
    // shadowGenerator.useBlurCloseExponentialShadowMap = true;
    // shadowGenerator.forceBackFacesOnly = true;
    // shadowGenerator.blurKernel = 5;
    // shadowGenerator.useKernelBlur = true;
    //
    // Freeze shadows
    // shadowGenerator.getShadowMap().refreshRate =
    //   BABYLON.RenderTargetTexture.REFRESHRATE_RENDER_ONCE;

    shadowGenerators.push(shadowGenerator);
  }

  // const instrumentation = new SceneInstrumentation(scene);

  /* Setup world meshes */

  const renderLoop = () => {
    scene.render();
    runInRenderLoop && runInRenderLoop();
  };

  engine.runRenderLoop(renderLoop);

  const resize = () => {
    engine.resize();
  };

  window.addEventListener('resize', resize);

  window.onbeforeunload = () => {
    window.removeEventListener('resize', resize);
    engine.stopRenderLoop();
    cancelAnimationFrame(engine._frameHandler); // trying
    engine.dispose();
  };

  return {
    scene,
    engine,
    camera,
    shadowGenerators,
    lights,
    renderLoop,
  };
};
