import withBase from "./withBase.js";
import alias from "../util/alias.js";
import subdivide from "../util/subdivide.js";

const showGuides = false;

const typeface = {
  yUnits: 10,
  markFraction: 0.9,
  subdivide: 1,
  cornerRadius: 0.1,
  letterSpacing: 2,
  padding: 0,
  // alias: 4,
  noise: 2,
  render({ glyph, xUnits, yUnits, width, unitSize, pattern, preDraw }) {
    const segmentSize = unitSize / this.subdivide;

    for (let i = 0; i < yUnits * this.subdivide; i++) {
      const y = i * segmentSize;
      for (let j = 0; j < xUnits * this.subdivide; j++) {
        const x = j * segmentSize;
        const innerSize = segmentSize * this.markFraction;
        const isFill = pattern[i * (xUnits * this.subdivide) + j];
        preDraw.rectangle({
          x,
          y,
          width: innerSize,
          height: innerSize,
          cornerRadius: segmentSize / 4,
          fill: this.colors.primary, // isFill ? this.colors.primary : this.colors.secondary,
          opacity: isFill ? 1 : this.bgOpacity,
        });
      }
    }
  },
};

/* prettier-ignore */
typeface.glyphs =  {
  " ": [
    4,
    0,0,0,0,
    0,0,0,0,
    0,0,0,0,
    0,0,0,0,
    0,0,0,0,
    0,0,0,0,
    0,0,0,0,
    0,0,0,0,
    0,0,0,0,
    0,0,0,0,
  ],
  "/": [
      13,
      0,0,0,0,0,0,0,0,0,0,0,0,0,
      0,0,0,0,0,0,1,0,0,0,0,0,0,
      0,0,0,0,0,1,1,1,0,0,0,0,0,
      0,0,0,0,1,1,1,1,1,0,0,0,0,
      0,0,0,1,1,1,1,1,1,1,0,0,0,
      0,0,1,1,1,1,1,1,1,1,1,0,0,
      0,0,0,0,0,1,1,1,0,0,0,0,0,
      0,0,0,0,0,1,1,1,0,0,0,0,0,
      0,0,0,0,0,1,1,1,0,0,0,0,0,
      0,0,0,0,0,0,0,0,0,0,0,0,0,
  ],
  "A": [
      9,
      0,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
  ],
  "B": [
      9,
      1,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,0,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,0,
  ],
  "C": [
      9,
      0,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,1,0,
  ],
  "D": [
      9,
      1,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,0,
  ],
  "E": [
      9,
      1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,1,
  ],
  "F": [
      9,
      1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
  ],
  "G": [
      9,
      0,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,1,1,1,1,
      1,1,0,0,0,1,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,0,1,1,
  ],
  "H": [
      9,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
  ],
  "I": [
      8,
      1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,
      0,0,0,1,1,0,0,0,
      0,0,0,1,1,0,0,0,
      0,0,0,1,1,0,0,0,
      0,0,0,1,1,0,0,0,
      0,0,0,1,1,0,0,0,
      0,0,0,1,1,0,0,0,
      1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,
  ],
    "J": [
      9,
      0,0,0,0,1,1,1,1,1,
      0,0,0,0,1,1,1,1,1,
      0,0,0,0,0,0,0,1,1,
      0,0,0,0,0,0,0,1,1,
      0,0,0,0,0,0,0,1,1,
      0,0,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,1,0,
  ],
  "K": [
      9,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,1,1,0,
      1,1,1,1,1,1,1,0,0,
      1,1,1,1,1,1,1,0,0,
      1,1,0,0,0,0,1,1,0,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
  ],
  "L": [
      9,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,1,1,
  ],
  "M": [
      12,
      1,1,1,1,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,0,0,0,1,1,
   ],
  "N": [
      9,
      1,1,0,0,0,0,0,1,1,
      1,1,1,0,0,0,0,1,1,
      1,1,1,1,0,0,0,1,1,
      1,1,1,1,1,0,0,1,1,
      1,1,0,1,1,1,0,1,1,
      1,1,0,0,1,1,1,1,1,
      1,1,0,0,0,1,1,1,1,
      1,1,0,0,0,0,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
  ],
  "O": [
      9,
      0,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,1,0,
  ],
  "o": [
      9,
      0,0,0,0,0,0,0,0,0,
      0,0,0,0,0,0,0,0,0,
      0,0,0,0,0,0,0,0,0,
      0,0,0,0,0,0,0,0,0,
      0,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,1,0,
  ],
  "P": [
      9,
      1,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
  ],
  "Q": [
      9,
      0,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,1,1,1,1,
      1,1,0,0,0,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,0,1,1,
  ],
  "R": [
      9,
      1,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,0,
      1,1,0,0,0,0,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
  ],
  "S": [
      9,
      0,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,0,0,
      1,1,1,1,1,1,1,1,0,
      0,1,1,1,1,1,1,1,1,
      0,0,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,1,0,
  ],
  "T": [
      10,
      1,1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,1,1,
      0,0,0,0,1,1,0,0,0,0,
      0,0,0,0,1,1,0,0,0,0,
      0,0,0,0,1,1,0,0,0,0,
      0,0,0,0,1,1,0,0,0,0,
      0,0,0,0,1,1,0,0,0,0,
      0,0,0,0,1,1,0,0,0,0,
      0,0,0,0,1,1,0,0,0,0,
      0,0,0,0,1,1,0,0,0,0,
  ],
  "U": [
      9,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,1,0,
  ],
  "V": [
      9,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,0,0,0,1,1,1,
      0,1,1,1,1,1,1,1,0,
      0,0,1,1,1,1,1,0,0,
  ],
  "W": [
      12,
      1,1,0,0,0,1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,0,0,0,1,1,
      1,1,0,0,0,1,1,0,0,0,1,1,
      0,1,1,1,1,1,1,1,1,1,1,0,
      0,0,1,1,1,0,0,1,1,1,0,0,
  ],
  "X": [
      9,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,0,0,0,1,1,1,
      0,1,1,1,0,1,1,1,0,
      0,0,1,1,1,1,1,0,0,
      0,0,0,1,1,1,0,0,0,
      0,0,1,1,1,1,1,0,0,
      0,1,1,1,0,1,1,1,0,
      1,1,1,0,0,0,1,1,1,
      1,1,0,0,0,0,0,1,1,
    ],
  "Y": [
      8,
      1,1,0,0,0,0,1,1,
      1,1,0,0,0,0,1,1,
      1,1,0,0,0,0,1,1,
      1,1,1,0,0,1,1,1,
      0,1,1,1,1,1,1,0,
      0,0,1,1,1,1,0,0,
      0,0,0,1,1,0,0,0,
      0,0,0,1,1,0,0,0,
      0,0,0,1,1,0,0,0,
      0,0,0,1,1,0,0,0,
  ],
  "Z": [
      9,
      1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,1,
      0,0,0,0,0,0,1,1,1,
      0,0,0,0,0,1,1,1,0,
      0,0,0,0,1,1,1,0,0,
      0,0,0,1,1,1,0,0,0,
      0,0,1,1,1,0,0,0,0,
      0,1,1,1,0,0,0,0,0,
      1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,1,
  ],
  "0": [
      9,
      0,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,1,1,1,
      1,1,0,0,0,1,1,1,1,
      1,1,0,0,1,1,1,1,1,
      1,1,0,1,1,1,0,1,1,
      1,1,1,1,1,0,0,1,1,
      1,1,1,1,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,1,0,
  ],
  "1": [
      9,
      1,1,1,1,1,0,0,0,0,
      1,1,1,1,1,1,0,0,0,
      0,0,0,0,1,1,0,0,0,
      0,0,0,0,1,1,0,0,0,
      0,0,0,0,1,1,0,0,0,
      0,0,0,0,1,1,0,0,0,
      0,0,0,0,1,1,0,0,0,
      0,0,0,0,1,1,0,0,0,
      1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,1,
  ],
  "2": [
      9,
      1,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      0,0,0,0,0,0,0,1,1,
      0,0,0,0,0,0,0,1,1,
      0,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,0,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,1
  ],
  "3": [
      9,
      1,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      0,0,0,0,0,0,0,1,1,
      0,0,0,0,0,0,0,1,1,
      0,1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,1,1,
      0,0,0,0,0,0,0,1,1,
      0,0,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,0,
  ],
  "4": [
      9,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,1,1,
      0,0,0,0,0,0,0,1,1,
      0,0,0,0,0,0,0,1,1,
      0,0,0,0,0,0,0,1,1,
      0,0,0,0,0,0,0,1,1,
  ],
  "5": [
      9,
      1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,1,1,1,1,1,1,0,
      0,1,1,1,1,1,1,1,1,
      0,0,0,0,0,0,0,1,1,
      0,0,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,0,
  ],
  "6": [
      9,
      0,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,0,0,
      1,1,0,0,0,0,0,0,0,
      1,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,1,0,
  ],
  "7": [
      9,
      1,1,1,1,1,1,1,0,0,
      1,1,1,1,1,1,1,1,0,
      0,0,0,0,0,0,1,1,0,
      0,0,0,0,0,0,1,1,0,
      0,0,0,0,1,1,1,1,1,
      0,0,0,0,1,1,1,1,1,
      0,0,0,0,0,0,1,1,0,
      0,0,0,0,0,0,1,1,0,
      0,0,0,0,0,0,1,1,0,
      0,0,0,0,0,0,1,1,0,
  ],
  "8": [
      9,
      0,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,1,0,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,1,0,
  ],
  "9": [
      9,
      0,1,1,1,1,1,1,1,0,
      1,1,1,1,1,1,1,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,0,0,0,0,0,1,1,
      1,1,1,1,1,1,1,1,1,
      0,1,1,1,1,1,1,1,1,
      0,0,0,0,0,0,0,1,1,
      0,0,0,0,0,0,0,1,1,
      0,0,0,0,0,0,0,1,1,
      0,0,0,0,0,0,0,1,1,
  ],
  ".": [
      2,
      0,0,
      0,0,
      0,0,
      0,0,
      0,0,
      0,0,
      0,0,
      0,0,
      1,1,
      1,1,
  ],
  "-": [
      9,
      0,0,0,0,0,0,0,0,0,
      0,0,0,0,0,0,0,0,0,
      0,0,0,0,0,0,0,0,0,
      0,0,0,0,0,0,0,0,0,
      1,1,1,1,1,1,1,1,1,
      1,1,1,1,1,1,1,1,1,
      0,0,0,0,0,0,0,0,0,
      0,0,0,0,0,0,0,0,0,
      0,0,0,0,0,0,0,0,0,
      0,0,0,0,0,0,0,0,0,
  ],
  '"': [
      5,
      1,1,0,1,1,
      1,1,0,1,1,
      1,1,0,1,1,
      0,0,0,0,0,
      0,0,0,0,0,
      0,0,0,0,0,
      0,0,0,0,0,
      0,0,0,0,0,
      0,0,0,0,0,
      0,0,0,0,0,
  ],
  ":": [
      3,
      0,0,0,
      1,1,1,
      1,1,1,
      1,1,1,
      0,0,0,
      0,0,0,
      1,1,1,
      1,1,1,
      1,1,1,
      0,0,0,
  ],
  "%": [
      11,
      0,0,0,0,0,0,0,0,0,1,1,
      0,1,1,1,0,0,0,0,1,1,1,
      0,1,1,1,0,0,0,1,1,0,0,
      0,1,1,1,0,0,1,1,0,0,0,
      0,0,0,0,0,1,1,0,0,0,0,
      0,0,0,0,1,1,0,0,0,0,0,
      0,0,0,1,1,0,0,1,1,1,0,
      0,0,1,1,0,0,0,1,1,1,0,
      1,1,1,0,0,0,0,1,1,1,0,
      1,1,0,0,0,0,0,0,0,0,0,
  ],
}

export default withBase(typeface);
