import { flattenVertices } from "./vertices";

class BlockModel {
  constructor(data, opt = {}) {
    opt = {
      position: { x: 0, y: 0, z: 0 },
      rotate: null,
      scale: { x: 1, y: 1, z: 1 },
      ...opt,
    };
    if (data) {
      this._data = data;
    } else {
      const { position, scale, rotate } = opt;
      this._data = {
        position,
        scale,
        rotate,
        vertices: [],
        indices: [],
        textures: [],
        textureFaces: [],
        lights: [],
        thrusters: [],
      };
    }
  }

  get shader() {
    return this._data.shader;
  }

  get textureShader() {
    return this._data.textureShader;
  }

  get thrusters() {
    return this._data.thrusters;
  }

  get lightIndices() {
    return this._data.lights;
  }

  get position() {
    return this._data.position;
  }

  get rotate() {
    return this._data.rotate;
  }

  get scale() {
    return this._data.scale;
  }

  get vertices() {
    return this._data.vertices;
  }

  get indices() {
    return this._data.indices;
  }

  get textures() {
    return this._data.textures;
  }

  get textureFaces() {
    return this._data.textureFaces;
  }

  _addToData(key, value) {
    this._data[key] = [...this._data[key], ...value];
  }

  _addVertices(vertices) {
    const { position, scale } = this._data;
    this._addToData("vertices", flattenVertices(vertices));
  }

  addVerticesAndUpdateIndices(vertices, indices) {
    indices = indices.map(v => v + this._data.vertices.length / 3);
    this._addVertices(vertices);
    return indices;
  }

  addVerticesAndIndices(vertices, indices) {
    indices = indices.map(v => v + this._data.vertices.length / 3);
    this._addVertices(vertices);
    this._addToData("indices", indices);
  }

  addLight({ vertices, indices }) {
    const newIndices = this.addVerticesAndUpdateIndices(vertices, indices);
    this._data.lights.push(...newIndices);
  }

  addLights(...args) {
    args.forEach(arg => this.addLight(arg));
  }

  addObject({ vertices, indices }, isLight = false) {
    this.addVerticesAndIndices(vertices, indices);
  }

  addThruster(thrust) {
    this._data.thrusters.push(thrust);
  }

  addTexture(texture) {
    this._data.textures.push(texture);
    return this._data.textures.length - 1;
  }

  addTextureFace(data) {
    this._data.textureFaces.push(data);
  }

  getTexture(idx) {
    return this._data.textures[idx];
  }

  exportJson() {}

  getData() {
    return this._data;
  }
}

["Vertices", "Indices", "Normals", "Uvs", "TexturePlanes"].forEach(s => {
  const key = s.toLowerCase();
  BlockModel.prototype[`set${s}`] = function (value) {
    this._data[key] = value;
  };
  BlockModel.prototype[`get${s}`] = function () {
    return this._data[key];
  };
});

export default BlockModel;
