import {
  loadShaders,
  shaderVars,
  renderRacer,
  loadDefaultShaders,
  getShaderName,
} from '@blockracers/util';
import createEnvironment from './createEnvironment';
import { Draw, Words, calculateWidth, calculateHeight } from '@a4m/graphics';
import { getHashFromIpfsUrl } from './util';

import {
  Color4,
  Engine,
  Effect,
  Scene,
  Vector3,
  ArcRotateCamera,
  DirectionalLight,
  ShadowGenerator,
  MeshBuilder,
} from '@babylonjs/core';

import { ShadowOnlyMaterial } from '@babylonjs/materials';

const canvas = document.createElement('canvas');
canvas.classList.add('racer-hero');

const randomButton = document.createElement('button');
randomButton.innerText = 'Random';
randomButton.addEventListener('click', () => {
  loadRacer();
});

const viewButton = document.createElement('a');
viewButton.classList.add('button');
viewButton.innerText = 'View Racer';

const buttonGroup = document.createElement('div');
buttonGroup.classList.add('button-group');
buttonGroup.append(viewButton);
buttonGroup.append(randomButton);

const container = document.querySelector('.main-hero');
container.innerHTML = '';
container.append(canvas);
container.append(buttonGroup);

const engine = new Engine(canvas, true, {}, true);

window.addEventListener('resize', function () {
  engine.resize();
});

const scene = new Scene(engine);

scene.shadowsEnabled = true;

const camera = new ArcRotateCamera(
  'Camera',
  // Alpha
  (-3 * Math.PI) / 4,
  // Beta
  Math.PI / 4,
  // Zoom (radius)
  10,
  // Target
  new Vector3(0, 2, 0),
  scene
);

// camera.position = new Vector3(-20, 5, 0);
camera.upperBetaLimit = Math.PI / 1.6;
camera.lowerBetaLimit = 0;

// Zoom
// camera.radius = 20;

camera.attachControl(canvas, true);
camera.lowerRadiusLimit = camera.radius;
camera.upperRadiusLimit = camera.radius;
camera.wheelPrecision = 50;
camera.panningSensibility = 0;

const shadowGenerators = [];

for (let i = 0; i < shaderVars.lightPositions.length; i += 3) {
  const position = new Vector3(
    shaderVars.lightPositions[i],
    shaderVars.lightPositions[i + 1],
    shaderVars.lightPositions[i + 2]
  );
  var light = new DirectionalLight('dir01', position.negate(), scene);
  light.position = position;

  light.intensity = shaderVars.ambientLightStrength;

  light.autoUpdateExtends = false;
  light.shadowMinZ = 10;
  light.shadowMaxZ = 70;

  const shadowGenerator = new ShadowGenerator(1024, light);

  // shadowGenerator.usePoissonSampling = true;
  // shadowGenerator.useBlurCloseExponentialShadowMap = true;
  // shadowGenerator.forceBackFacesOnly = true;
  // shadowGenerator.blurKernel = 5;
  // shadowGenerator.useKernelBlur = true;
  //
  // Freeze shadows
  // shadowGenerator.getShadowMap().refreshRate =
  //   RenderTargetTexture.REFRESHRATE_RENDER_ONCE;

  shadowGenerators.push(shadowGenerator);
}

scene.clearColor = new Color4(0, 0, 0, 0); //.FromHexString("#93C4D6");

loadDefaultShaders(Effect);

// const ground = MeshBuilder.CreateGround(
//   "ground",
//   { width: 50, height: 50 },
//   scene
// );

// ground.position = new Vector3(0, 0, 0);
// ground.receiveShadows = true;

// var groundMaterial = new ShadowOnlyMaterial("ground", scene);
// ground.material = groundMaterial;

// todo: fix

camera.alpha += 0.8;
scene.beforeRender = () => (camera.alpha += 0.003);

// Register a render loop to repeatedly render the scene
engine.runRenderLoop(function () {
  scene.render();
});

let racerMesh, envMesh;

const loadRacer = async () => {
  if (racerMesh) {
    racerMesh.dispose();
  }
  if (envMesh) {
    envMesh.dispose();
  }

  const existingTitle = document.querySelector('.racer-title');
  const existingSubtitle = document.querySelector('.racer-subtitle');
  if (existingTitle) {
    container.removeChild(existingTitle);
  }
  if (existingSubtitle) {
    container.removeChild(existingSubtitle);
  }

  const { racerData, token } = await fetch('/random-asset').then(res =>
    res.json()
  );

  viewButton.href = `/view/${getHashFromIpfsUrl(token.asset_url)}`;

  [randomButton, viewButton].forEach(button => {
    button.style.backgroundColor = '#000';
    button.style.color = racerData.colors[2];
  });

  envMesh = createEnvironment(scene, racerData.colors[0]);
  envMesh.position = new Vector3(0, 0, 0);

  loadShaders(
    racerData.version !== 1.1
      ? racerData.shaders.map(shader => ({
          ...shader,
          name: getShaderName(shader.name, racerData.meta.name),
          // name: shader.name,
        }))
      : Object.values(racerData.shaders).map(shader => ({
          ...shader,
          name: getShaderName(shader.shader, racerData.meta.name),
          // name: shader.shader,
        })),
    Effect
  );

  const racerObj = await renderRacer(racerData, scene);

  racerMesh = racerObj.racerMesh;

  racerMesh.position = new Vector3(-3, 2, 0);

  shadowGenerators.forEach(shadowGenerator => {
    shadowGenerator.addShadowCaster(racerMesh, true);
  });

  const titleHeight = 80;
  const subtitleHeight = 15;

  const splitTitle = `#${token.tokenNo} ${racerData.meta.name}`
    .toUpperCase()
    .split(' ')
    .join('\n');

  const width = calculateWidth('sixth', titleHeight, splitTitle);

  const titleCanvas = document.createElement('canvas');
  titleCanvas.classList.add('racer-title');

  const subtitleCanvas = document.createElement('canvas');
  subtitleCanvas.classList.add('racer-subtitle');
  container.prepend(titleCanvas, subtitleCanvas);

  const title = Words(
    Draw({
      canvas: titleCanvas,
      width,
      height: calculateHeight('sixth', titleHeight, splitTitle),
    })
  );

  const subtitle = `MODEL: ${token.model.toUpperCase()} GENERATION: ${
    token.generation
  } FOUNDRY: ${token.foundry.toUpperCase()}`;

  const writeSubtitle = Words(
    Draw({
      canvas: subtitleCanvas,
      width: calculateWidth('first', subtitleHeight, subtitle),
      height: subtitleHeight,
    })
  );

  title({
    typeface: 'sixth',
    noise: 24,
    height: titleHeight,
    colors: {
      primary: racerData.colors[2],
      secondary: racerData.colors[2],
    },
    content: splitTitle,
  });

  writeSubtitle({
    typeface: 'first',
    height: subtitleHeight,
    invert: true,
    padding: 1,
    colors: {
      primary: racerData.colors[2],
      secondary: racerData.colors[2],
    },
    content: subtitle,
  });
};

loadRacer();
