export default `precision highp float;


// Refs
uniform mat4 world;
uniform sampler2D t1;
uniform sampler2D bump;
uniform vec2 textureOffset;
uniform vec2 textureScale;
uniform vec3 lightPositions[8];
uniform vec3 lightColors[8];
uniform float ambientLightStrength;
uniform float minimalAmbientLight;

// Lights
varying vec3 vPositionW;
varying vec3 vNormalW;
varying vec2 vUV;
varying mat3 TBN;
varying vec3 vNormal;

uniform vec3 colors[3];

mat3 makeScale(vec2 s) {
    mat3 m = mat3( s.x, 0, 0, 0, s.y, 0, 0, 0, 1);
    return m;
}

void main(void) {

    // Light

    vec2 textureCoord = textureScale * vUV;
    textureCoord += textureOffset;

    vec4 rgbTex = texture2D(bump, textureCoord);

    vec3 textureNormal = normalize(rgbTex * 2. - 1.).rgb;

    textureNormal = mix(textureNormal, vec3(0,0,1.), 0.5);

    textureNormal.z = -textureNormal.z;

    vec3 newNormal  =  normalize(vec3(world * vec4(textureNormal, 0.0)));

    vec4 texel4 = texture2D(t1, textureCoord ).rgba;

    vec3 texel = texel4.rgb;

    vec3 fragColor = colors[1];

    // Lighting
    float diffuse = 0.;

    for (int i = 0; i < 8; i++) {

        vec3 lightCol = lightColors[i];
      
        if ((lightCol.r + lightCol.g + lightCol.b) == 0.) {
          continue;
        }

        vec3 lightDir = normalize(lightPositions[i] - vPositionW);

        float ndl = max(dot(vNormalW, lightDir), 0.0);
        ndl *= ambientLightStrength;
        diffuse += ndl; 
    }

    vec3 colorWithLighting = max(minimalAmbientLight, diffuse) * colors[0];

    float alpha = texel4.a;
    fragColor = mix(colorWithLighting, fragColor, alpha);
    gl_FragColor = vec4(fragColor, 1.);
}`;
