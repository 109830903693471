export default `
precision highp float;

// Lights
varying vec3 vPositionW;
varying vec3 vNormalW;
varying vec2 vUV;

// Refs
uniform vec3 lightPositions[8];
uniform vec3 lightColors[8];
uniform float ambientLightStrength;
uniform float minimalAmbientLight;

uniform vec3 colors[2];

void main(void) {

    // Lighting
    float diffuse = 0.;

    // diffuse
    for (int i = 0; i < 8; i++) {

        vec3 lightCol = lightColors[i];
      
        if ((lightCol.r + lightCol.g + lightCol.b) == 0.) {
          continue;
        }

        vec3 lightDir = normalize(lightPositions[i] - vPositionW);

        float ndl = max(dot(vNormalW, lightDir), 0.0);
        ndl *= ambientLightStrength;
        diffuse += ndl; 
    }

    vec3 colorWithLighting = max(minimalAmbientLight, diffuse) * colors[0];

    gl_FragColor = vec4(vec3(0.,1.,0.), 1.);
}`;
