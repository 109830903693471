import { randomColor, randomInt } from '../util';
import { RED } from '../util/constants';
import { PointHelper } from '../util/helpers';
import * as shaders from './shaders';
import * as shaderVars from './shaderVars';
import { Color4, Color3, StandardMaterial, Effect } from '@babylonjs/core';

export const loadDefaultShaders = Effect => {
  Effect.ShadersStore['blockInstanceVertexShader'] = shaders.blockInstanceVert;
  Effect.ShadersStore['blockInstanceFragmentShader'] =
    shaders.blockInstanceFrag;
  /* Light texture */
  Effect.ShadersStore['lightVertexShader'] = shaders.lightVert;
  Effect.ShadersStore['lightFragmentShader'] = shaders.lightFrag;
  /* Env texture */
  Effect.ShadersStore['envTextureVertexShader'] = shaders.envTextureVert;
  Effect.ShadersStore['envTextureFragmentShader'] = shaders.envTextureFrag;
  /* Building texture */
  Effect.ShadersStore['buildingTextureVertexShader'] =
    shaders.buildingTextureVert;
  Effect.ShadersStore['buildingTextureFragmentShader'] =
    shaders.buildingTextureFrag;
  /* Building block */
  Effect.ShadersStore['buildingBlockVertexShader'] = shaders.buildingBlockVert;
  Effect.ShadersStore['buildingBlockFragmentShader'] =
    shaders.buildingBlockFrag;
  Effect.ShadersStore['transparentFragmentShader'] = shaders.transparentFrag;
  Effect.ShadersStore['transparentVertexShader'] = shaders.transparentVert;
  Effect.ShadersStore['instanceFragmentShader'] = shaders.instanceFrag;
  Effect.ShadersStore['instanceVertexShader'] = shaders.instanceVert;
  Effect.ShadersStore['cellFragmentShader'] = shaders.cellFrag;
  Effect.ShadersStore['cellVertexShader'] = shaders.cellVert;
  Effect.ResetCache();
};

export { default as createTexture } from './createTexture';
export { default as loadShaders } from './loadShaders';
export { default as getShaderMaterial } from './getShaderMaterial';
export { default as textureShader } from './textureShader';
export { default as cellShader } from './cellShader';
export { default as flatShader } from './flatShader';
export { default as transparentShader } from './transparentShader';
export { default as instanceShader } from './instanceShader';

export { shaderVars, shaders };

export function basic(scene, color = randomColor()) {
  const basic = new StandardMaterial('', scene);

  basic.diffuseColor = Color3.FromHexString(color);
  basic.ambientColor = new Color3(0.23, 0.98, 0.53);

  return basic;
}

export function outline(mesh, edgesWidth = 2.0, edgesColor = '#000000') {
  mesh.enableEdgesRendering(0.95, true);
  mesh.edgesWidth = edgesWidth;
  mesh.edgesColor = Color4.FromColor3(Color3.FromHexString(edgesColor), 1);
}

export function wireframe(scene) {
  const wireframe = new StandardMaterial('', scene);
  wireframe.color = RED;
  wireframe.wireframe = true;
  return wireframe;
}
