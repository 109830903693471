import { transparentShader, getShaderMaterial, shaderVars } from "../materials";
import { Color4 } from "@babylonjs/core";

const epsilon = 0.95;
const edgeWidth = 0.4;
const edgeColor = new Color4(0, 0, 0, 1);

const showMeshEdges = (mesh, { epsilon, edgeWidth, edgeColor }) => {
  mesh.enableEdgesRendering(epsilon);
  mesh.edgesWidth = edgeWidth;
  mesh.edgesColor = edgeColor;
};

export default (mesh, scene, options) => {
  const allOptions = { epsilon, edgeWidth, edgeColor, ...options };
  const meshOutline = mesh.clone("meshOutline");
  const transparentMaterial = transparentShader(scene);
  meshOutline.material = transparentMaterial;

  showMeshEdges(meshOutline, allOptions);

  return meshOutline;
};
