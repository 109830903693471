export default `
precision highp float;

// Lights
varying vec3 vPositionW;
varying vec3 vNormalW;
varying vec2 vUV;

// Refs
uniform vec3 lightPositions[8];
uniform vec3 lightColors[8];
uniform float ambientLightStrength;
uniform float minimalAmbientLight;

uniform vec3 colors[2];

#define FOGMODE_NONE 0.
#define FOGMODE_EXP 1.
#define FOGMODE_EXP2 2.
#define FOGMODE_LINEAR 3.
#define E 2.71828

uniform vec4 vFogInfos;
uniform vec3 vFogColor;
varying float fFogDistance;

float CalcFogFactor()
{
 float fogCoeff = 1.0;
 float fogStart = vFogInfos.y;
 float fogEnd = vFogInfos.z;
 float fogDensity = vFogInfos.w;

 if (FOGMODE_LINEAR == vFogInfos.x)
 {
  fogCoeff = (fogEnd - fFogDistance) / (fogEnd - fogStart);
 }
 else if (FOGMODE_EXP == vFogInfos.x)
 {
  fogCoeff = 1.0 / pow(E, fFogDistance * fogDensity);
 }
 else if (FOGMODE_EXP2 == vFogInfos.x)
 {
  fogCoeff = 1.0 / pow(E, fFogDistance * fFogDistance * fogDensity * fogDensity);
 }

 return clamp(fogCoeff, 0.0, 1.0);
}

void main(void) {

    // Lighting
    float diffuse = 0.;

    // diffuse
    for (int i = 0; i < 8; i++) {

        vec3 lightCol = lightColors[i];
      
        if ((lightCol.r + lightCol.g + lightCol.b) == 0.) {
          continue;
        }

        vec3 lightDir = normalize(lightPositions[i] - vPositionW);

        float ndl = max(dot(vNormalW, lightDir), 0.0);
        ndl *= ambientLightStrength;
        diffuse += ndl; 
    }

    float fog = CalcFogFactor();

    vec3 color = max(minimalAmbientLight, diffuse) * colors[0];
    color.rgb = mix(colors[0], color, vPositionW.y / 100.);
    color.rgb = fog * color.rgb + (1.0 - fog) * vFogColor;

    gl_FragColor = vec4(color, 1.);
    // gl_FragColor = vec4(vPositionW.y / 20., 1., 1., 1.);
    //
}`;
