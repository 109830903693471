import { VertexData, Mesh } from "@babylonjs/core";

const defaultOptions = {
  vertices: [],
  indices: [],
  normals: [],
  uvs: [],
  flatShading: true,
  updatable: false,
};

export default function createShape(name = "", options, scene) {
  options = { ...defaultOptions, ...options };

  const { vertices, indices, normals, uvs, updatable, flatShading } = options;

  const vertexData = new VertexData();
  vertexData.positions = vertices;
  vertexData.indices = [...indices]; // I hate this bug

  if (!normals.length) {
    // VertexData.ComputeNormals(positions, indices, normals);
    // vertexData.normals = normals; //Assignment of normal to vertexData added
  }

  if (uvs.length) {
    vertexData.uvs = uvs;
  }
  const shape = new Mesh(name, scene);
  vertexData.applyToMesh(shape, updatable);

  if (flatShading) {
    shape.convertToFlatShadedMesh();
  }

  return shape;
}
