import { shaders } from ".";

const fixFragShader = shaderStr => {
  return shaderStr.replace(/< lightCount/g, "< 8");
};

export default (racerShaders, Effect) => {
  racerShaders.forEach(shader => {
    const shaderName = shader.name.split("_").pop();
    Effect.ShadersStore[`${shader.name}VertexShader`] =
      shaders[`${shaderName}Vert`];
    Effect.ShadersStore[`${shader.name}FragmentShader`] =
      shaders[`${shaderName}Frag`];
  });

  Effect.ResetCache();
};
