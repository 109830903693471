export default (arr, xUnits, subdivisions, subdivideFns = {}) => {
  const yUnits = arr.length / xUnits;

  const newX = xUnits * subdivisions;
  const newY = yUnits * subdivisions;

  const subdividedArray = new Array(newX * newY).fill(null);

  const sq = subdivisions * subdivisions;

  arr.forEach((item, i) => {
    let newArray;
    if (subdivideFns[item]) {
      newArray = subdivideFns[item](subdivisions);
    } else {
      newArray = new Array(subdivisions * subdivisions).fill(item);
    }
    // newArray[0] = 0;
    const row = Math.floor(i / xUnits);
    const col = i - row * xUnits;

    for (let j = 0; j < subdivisions; j++) {
      for (let k = 0; k < subdivisions; k++) {
        const startingIndex = row * subdivisions * newX + col * subdivisions; // * sq + col * subdivisions;
        subdividedArray[startingIndex + j * newX + k] =
          newArray[j * subdivisions + k];
      }
    }
  });

  return subdividedArray;
};

// export default (arr, xUnits, subdivisions) => {
//   const lineCount = arr.length / xUnits;
//   const lines = [];
//   for (let i = 0; i < lineCount; i++) {
//     const line = arr
//       .splice(0, xUnits)
//       .reduce((a, b) => [...a, ...new Array(subdivisions).fill(b)], []);
//     lines.push(...new Array(subdivisions).fill(line));
//   }
//   return lines.flat();
// };
