import { randomInt } from "../util/random";

/* prettier-ignore */
export const lightPositions = [
   -4, 20, -5,
   // 20, 3, 0,
   // -2, 20, -5,
   // -4, 20, -5,
   // -4, 20, -5,
   // -4, 20, -5,
   // -4, 20, -5,
   // -4, 20, -5,
];

/* prettier-ignore */
export const lightColors = [
    "#ffffff",
    "#000000",
    "#000000",
    "#000000",
    "#000000",
    "#000000",
    "#000000",
    "#000000",
];

export const ambientLightStrength = 1.2; // 1.2
export const minimalAmbientLight = 0.2; //0.2;
